import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import _ from 'lodash';
import * as TELEHEALTH from '../constants/telehealth';

const initialState = Immutable({
	token: '',
	users: [],
	user: {},
	error: null,
	providers: [],
	pharmacies: [],
	selected_provider: { providerDetails: {} },
	selected_provider_type: null,
	provider_types: [
		{
			id: 3,
			name: 'Medical Visit',
			description: 'Board certified doctors and pediatricians available 24/7.',
			amount: null,
			amountDescription: 'Per Visit',
			useCases: [
				'Cold & Flu',
				'Pink Eye',
				'Sinus Infection',
				'Sore Throat',
				'UTI (females only)',
				'and more...',
			],
		},
		{
			id: 5,
			name: 'Therapy Counseling',
			description:
				'Private, secure, and confidential therapy sessions. By appointment only.',
			amount: null,
			amountDescription: 'Per Session',
			useCases: [
				'Anxiety',
				'Grief and Loss',
				'Relationship Issues',
				'Sadness and Stress',
				'Sexuality',
				'and more...',
			],
		},
		{
			id: 6,
			name: 'Psychiatry',
			description:
				"Psychiatrists typically don't provide therapy, but can prescribe medications when appropriate.",
			amount: null,
			amountDescription: 'First Visit\n\r$99 Follow Up',
			useCases: [
				'Anxiety',
				'Bipolar Disorder',
				'Depression',
				'Insomnia',
				'Trauma and PTSD',
				'and more...',
			],
		},
	],
	chief_complaint_results: [],
	chief_complaint: null,
	chief_complaint_comment: null,
	billing_info: null,
	mdlive_appt_response: null,
	existing_mdlive_appt: {
		appointments: [],
	},
	mdlive_appt_information: null,
	image: {
		id: null,
	},
});

export default (state = initialState, action) => {
	switch (action.type) {
		case TELEHEALTH.FETCH_AUTH_TOKEN_SUCCESS:
			return state.merge({
				users: action.users,
				error: null,
			});
		case TELEHEALTH.SET_AUTH_TOKEN:
			return state.merge({
				token: action.token,
			});
		case TELEHEALTH.SET_USER_ID:
			return state.merge({
				user: {
					id: action.id,
				},
			});
		case TELEHEALTH.SET_SELECTED_PROVIDER_TYPE:
			return state.set('selected_provider_type', action.provider);
		case TELEHEALTH.FETCH_PROVIDERS_SUCCESS:
			return state.merge({
				providers: action.providers,
			});
		case TELEHEALTH.SET_SELECTED_PROVIDER: {
			const newObj = {
				selected_provider: action.provider,
				selected_provider_id: _.get(action, 'provider.id', -1),
			};
			return state.merge(newObj);
		}
		case TELEHEALTH.FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS_SUCCESS:
			return state.set('chief_complaint_results', action.results);
		case TELEHEALTH.SET_SELECTED_CHIEF_COMPLAINT:
			return state.set('chief_complaint', action.complaint);
		case TELEHEALTH.SET_SELECTED_CHIEF_COMPLAINT_COMMENT:
			return state.set('chief_complaint_comment', action.comment);
		case TELEHEALTH.FETCH_PATIENT_BILLING_INFO_SUCCESS:
			return state.set('billing_info', action.results);
		case TELEHEALTH.FETCH_SPECIFIC_PROVIDER_SUCCESS:
			return state.merge({
				selected_provider: {
					availabilityDetails: action.availabilityDetails,
					providerDetails: action.providerDetails,
				},
			});
		case TELEHEALTH.FETCH_SPECIFIC_PROVIDER_ERROR:
			return state.merge({
				error: action.error,
			});
		case TELEHEALTH.FETCH_PHARMACY_SUCCESS:
			return state.set('pharmacies', action.results);
		case TELEHEALTH.SUBMIT_MDLIVE_APPOINTMENT_SUCCESS:
			return state.set('mdlive_appt_response', action.mdliveResponse);
		case TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_SUCCESS:
			return state.set('existing_mdlive_appt', action.mdliveAppointment);
		case TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_INFO_SUCCESS:
			return state.set('mdlive_appt_information', action.mdliveAppointmentInfo);
		case TELEHEALTH.CANCEL_MDLIVE_APPOINTMENT_SUCCESS:
			return state.merge({
				existing_mdlive_appt: null,
				mdlive_appt_information: null,
			});
		case TELEHEALTH.FETCH_PROVIDER_TYPES_PRICE_SUCCESS: {
			const providerTypes = state.provider_types.map(type => {
				if (type.id === action.providerTypeId) {
					const newType = type.set('amount', `$${action.results.final_amount}`);
					return newType;
				}
				return type;
			});
			return state.merge({ provider_types: providerTypes });
		}
		case TELEHEALTH.IMAGE_UPLOAD_SUCCESS:
			return state.merge({
				image: {
					id: action.imageId,
				},
			});

		case TELEHEALTH.IMAGE_UPLOAD_FAILURE:
		case TELEHEALTH.FETCH_PROVIDER_TYPES_PRICE_FAILURE:
		case TELEHEALTH.CANCEL_MDLIVE_APPOINTMENT_FAILURE:
		case TELEHEALTH.SUBMIT_MDLIVE_APPOINTMENT_FAILURE:
		case TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_FAILURE:
		case TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_INFO_FAILURE:
		case TELEHEALTH.FETCH_AUTH_TOKEN_ERROR:
		case TELEHEALTH.FETCH_PROVIDERS_ERROR:
		case TELEHEALTH.FETCH_PHARMACY_ERROR:
		case TELEHEALTH.FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS_ERROR:
		case TELEHEALTH.FETCH_PATIENT_BILLING_INFO_ERROR:
			// alert('Sorry! We could not complete your request');
			return state.merge({
				error: action.error,
			});
		case 'RESET':
			return initialState;
		default:
			return state;
	}
};

const telehealthState = state => state.telehealth;

export const getSelectedPatientId = createSelector(
	[telehealthState],
	state => state.user.id
);

export const getToken = createSelector([telehealthState], state => state.token);
