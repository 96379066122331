import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { updatePatientHealthHistory as updatePatientHealthHistoryAction } from '../../redux/actions/patient';
import i18n from 'i18next';

function Checkbox({ field, type, checked }) {
	return <input {...field} type="checkbox" checked={checked} />;
}

const HealthHistory = ({
	patient,
	history,
	onSubmit,
	submitButtonText,
	updatePatientHealthHistory,
}) => {
	const { patient_profile: profile } = patient;
	const showPediatricQuestions = profile.age < 13;
	const showInfantQuestions = profile.age < 2;
	const showFemaleQuestions =
		profile.gender === 'Female' &&
		!showPediatricQuestions &&
		!showInfantQuestions;

	if (!showFemaleQuestions && !showPediatricQuestions) {
		history.push('/application/health-profile/health-conditions');
	}
	return (
		<div>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.personal-info.header', 'Now we need to ask you some personal questions.') }</h1>
			</div>
			<Formik
				initialValues={{
					is_breast_feeding:
						_.get(patient, 'health_history.personal_info.is_breast_feeding', false) ||
						false,
					is_pregnant:
						_.get(patient, 'health_history.personal_info.is_pregnant', false) ||
						false,
					menstrual_cycle:
						_.get(patient, 'health_history.personal_info.menstrual_cycle', '') || '',
					'Birth complications':
						_.get(patient, 'health_history.pediatric_profile', {})[
							'Birth complications'
						] || false,
					'Birth complications explanation':
						_.get(patient, 'health_history.pediatric_profile', {})[
							'Birth complications explanation'
						] || '',
					'Childcare outside home':
						_.get(patient, 'health_history.pediatric_profile', {})[
							'Childcare outside home'
						] || false,
					'Current Diet':
						_.get(patient, 'health_history.pediatric_profile', {})['Current Diet'] ||
						'',
					'Immunization up to date?':
						_.get(patient, 'health_history.pediatric_profile', {})[
							'Immunization up to date?'
						] || false,
					'Last shot':
						_.get(patient, 'health_history.pediatric_profile', {})['Last shot'] || '',
					Siblings:
						_.get(patient, 'health_history.pediatric_profile', {}).Siblings || false,
					'Smoking exposure':
						_.get(patient, 'health_history.pediatric_profile', {})[
							'Smoking exposure'
						] || false,
					'Current weight in lbs':
						_.get(patient, 'health_history.pediatric_profile', {})[
							'Current weight in lbs.'
						] || '',
				}}
				validate={(values) => {
					const errors = {};
					if (showInfantQuestions) {
						if (
							values['Birth complications'] &&
							values['Birth complications explanation'].trim().length === 0
						) {
							errors['Birth complications explanation'] = i18n.t('LABEL.components.forms.personal-info.birth-complications-error-msg', 'You must provide an explanation for your birth complication.');
						}
					}
					if (showPediatricQuestions) {
						if (
							!values['Immunization up to date?'] &&
							values['Last shot'].trim().length === 0
						) {
							errors['Last shot'] =  i18n.t('LABEL.components.forms.personal-info.last-shot-error-msg', 'You must provide the date of last shot.');
						}
					}
					if (showFemaleQuestions) {
						// if(!values.is_breast_feeding) errors.is_breast_feeding = "You must indicate if you are breast feeding";
						// if(!values.is_pregnant)
					}
					return errors;
				}}
				onSubmit={(values) => {
					if (showFemaleQuestions) {
						updatePatientHealthHistory({
							personal_information: {
								is_breast_feeding: values.is_breast_feeding,
								is_pregnant: values.is_pregnant,
								menstrual_cycle: values.menstrual_cycle,
							},
						});
					}
					if (showPediatricQuestions) {
						updatePatientHealthHistory({
							pediatric_profile: {
								'Childcare outside home': values['Childcare outside home'],
								'Immunization up to date?': values['Immunization up to date?'],
								'Last shot': values['Last shot'],
								Siblings: values.Siblings,
								'Smoking exposure': values['Smoking exposure'],
								'Current weight in lbs.': values['Current weight in lbs'],
								...(showInfantQuestions
									? {
										'Birth complications': values['Birth complications'],
										'Birth complications explanation':
												values['Birth complications explanation'],
										'Current Diet': values['Current Diet'],
									  }
									: {}),
							},
						});
					}
					if (onSubmit) {
						onSubmit(values);
					}
				}}
			>
				{({ values }) => (
					<Form>
						<div className="app-content-block-body">
							{showFemaleQuestions && (
								<>
									<label className="checkbox-label">
										<Field
											name="is_breast_feeding"
											as="checkbox"
											component={Checkbox}
											checked={values.is_breast_feeding}
										/>
										<span className="checkmark" />
										{ i18n.t('LABEL.components.forms.personal-info.breastfeed-label', 'I am breast feeding.') }
									</label>
									<label className="checkbox-label">
										<Field
											name="is_pregnant"
											as="checkbox"
											component={Checkbox}
											checked={values.is_pregnant}
										/>
										<span className="checkmark" />
										{ i18n.t('LABEL.components.forms.personal-info.pregnant-label', 'I am pregnant.') }
									</label>
									<div className="input-container">
										<label>
											{ i18n.t('LABEL.components.forms.personal-info.menstrual-cycle-label', 'Date of Last Menstrual Cycle') }
										</label>
										<Field name="menstrual_cycle" />
										<ErrorMessage name="menstrual_cycle" component="div" />
									</div>
								</>
							)}
							{showInfantQuestions && (
								<>
									<label className="checkbox-label">
										<Field
											name="Birth complications"
											as="checkbox"
											component={Checkbox}
											checked={values['Birth complications']}
										/>
										<span className="checkmark" />
										{ i18n.t('LABEL.components.forms.personal-info.birth-complications-label', 'Birth Complications?') }
									</label>
									{values['Birth complications'] && (
										<div className="input-container">
											<label>
												{ i18n.t('LABEL.components.forms.personal-info.birth-complications-explanation', 'Birth Complications Explanation') }
											</label>
											<Field name="Birth complications explanation" />
											<ErrorMessage
												name="Birth complications explanation"
												component="div"
											/>
										</div>
									)}
									<div className="input-container">
										<label>
											{ i18n.t('LABEL.components.forms.personal-info.diet-label', 'Current Diet') }
										</label>
										<Field name="Current Diet" />
										<ErrorMessage name="Current Diet" component="div" />
									</div>
								</>
							)}
							{showPediatricQuestions && (
								<>
									<label className="checkbox-label">
										<Field
											name="Childcare outside home"
											as="checkbox"
											component={Checkbox}
											checked={values['Childcare outside home']}
										/>
										<span className="checkmark" />
										{ i18n.t('LABEL.components.forms.personal-info.childcare-outside-label', 'Childcare Outside Home?') }
									</label>
									<label className="checkbox-label">
										<Field
											name="Immunization up to date?"
											as="checkbox"
											component={Checkbox}
											checked={values['Immunization up to date?']}
										/>
										<span className="checkmark" />
										{ i18n.t('LABEL.components.forms.personal-info.immunization-label', 'Immunization up to Date?') }
									</label>
									{!values['Immunization up to date?'] && (
										<div className="input-container">
											<label>{ i18n.t('LABEL.components.forms.personal-info.last-shot', 'Last Shot') }</label>
											<Field name="Last shot" />
											<ErrorMessage name="Last shot" component="div" />
										</div>
									)}
									<label className="checkbox-label">
										<Field
											name="Siblings"
											as="checkbox"
											component={Checkbox}
											checked={values.Siblings}
										/>
										<span className="checkmark" />
										{ i18n.t('LABEL.components.forms.personal-info.siblings-label', 'Has Siblings?') }
									</label>
									<label className="checkbox-label">
										<Field
											name="Smoking exposure"
											as="checkbox"
											component={Checkbox}
											checked={values['Smoking exposure']}
										/>
										<span className="checkmark" />
										{ i18n.t('LABEL.components.forms.personal-info.smoking-label', 'Has Smoking Exposure?') }
									</label>
									<div className="input-container">
										<label>{ i18n.t('LABEL.components.forms.personal-info.current-weight', 'Current Weight in lbs.') }</label>
										<Field name="Current weight in lbs" />
										<ErrorMessage name="Current weight in lbs" component="div" />
									</div>
								</>
							)}
						</div>
						<div className="app-content-block-footer">
							<div className="row" style={{justifyContent: 'space-between', alignItems: 'center'}}>

								<div className="button-container-left">
									<button type="button" className="default" onClick={() => history.goBack()} style={{marginTop: 15}}>
										{ i18n.t('LABEL.components.forms.personal-info.back-btn', 'Back') }
									</button>
								</div>            
								<div className="button-container-right">
									<button type="submit">{submitButtonText}</button>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

HealthHistory.propTypes = {
	submitButtonText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	updatePatientHealthHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	patient: state.patient,
});

const mapDispatchToProps = (dispatch) => ({
	updatePatientHealthHistory: (patientId, healthHistory) =>
		dispatch(updatePatientHealthHistoryAction(patientId, healthHistory)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(HealthHistory));
