import React, { useState } from "react";

const useLoader = (initialState) => {
	const [loading, setLoading] = useState(initialState);
    
	const Loader = () => loading ? (
		<div id="loading-container">
			<div className="loading-box">
				<lottie-player
					src="https://assets3.lottiefiles.com/datafiles/w8kbBwzPRk7sTCe/data.json"
					background="transparent"
					speed="1"
					style={{ width: 100, height: 100 }}
					loop
					autoplay
				/>
			</div>
		</div>
	) : null;

	return [Loader, setLoading];
};

export default useLoader;