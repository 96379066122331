import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import * as PATIENT from '../constants/patient';

const initialState = Immutable({
	currentPatientId: null,
	family_members: [],
	patient_profile: {
		affiliation: {},
		state: {},
		pharmacy: {},
		recent_notes: [],
		family_members: [],
		recent_activity_histories: [],
		chat_histories: [],
		upcoming_appointments: [],
	},
	health_history: {
		health_conditions: [],
		personal_info: {},
		evr_medications: [],
		allergies: [],
		source_for_ccd: null,
		is_taking_medication: 'Yes',
		has_allergies: 'Yes',
		external_reported_surgeries: [],
		surgeries_taken: [],
		had_surgeries: 'Yes',
		has_medical_conditions: 'Yes',
		pediatric_profile: {},
		pediatric_profile_completed: true,
		skip_health_history: false,
	},
	surgeries_taken: [],
	behavioral_history: {
		behavioral_health_reasons: [],
		behavioral_mconditions: [],
		behavioral_family_history: [],
	},
});

export default (state = initialState, action) => {
	switch (action.type) {
		case PATIENT.ADD_FAMILY_PROFILE:
			const family = Immutable.asMutable(state.family_members);
			family.push(action.profile.patient_profile);
			return state.set('family_members', family);
		case PATIENT.RESET_FAMILY_PROFILE:
			return state.set('family_members', []).set('behavioral_history', initialState.behavioral_history);
		case PATIENT.SET_PROFILE:
			return state.set('patient_profile', action.profile.patient_profile);
		case PATIENT.SET_HEALTH_HISTORY:
			return state.set('health_history', action.history);
		case PATIENT.SET_CURRENT_ID:
			return state.set('currentPatientId', action.patientId).set('behavioral_history', initialState.behavioral_history);
		case PATIENT.COPY_PATIENT_TO_PATIENT_PROFILE:
			return state.set('patient_profile', action.patient);
		case PATIENT.SET_CURRENT_STATE: 
			return state.set('current_state', action.currentState);
		case PATIENT.SET_SELECTED_STATE:
			return state.set('selected_state', action.selectedState);
		case PATIENT.SET_BEHAVIORAL_HEALTH_HISTORY:
			return state.set('behavioral_history', action.behavioralHistory);
		case 'RESET':
			return initialState;
		default:
			return state;
	}
};

const patientState = state => state.patient;

export const getCurrentPatientId = createSelector(
	[patientState],
	state => state.currentPatientId
);

export const getPatientProfile = createSelector(
	[patientState],
	state => state.patient_profile
);

export const getHealthHistory = createSelector(
	[patientState],
	state => state.health_history
);
