import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import {
	createMedication as createMedicationAction,
	removeMedication as removeMedicationAction,
	updatePatientHealthHistory as updatePatientHealthHistoryAction,
} from '../../redux/actions/patient';
import api from '../../services/api';
import { IconSVG } from '../icon';
import i18n from 'i18next';

const useMedicationSuggestions = () => {
	const [medications, setMedications] = useState([]);

	const fetchMedicationSuggestions = async (token, search) => {
		const response = await api.fetchMedicationSuggestions(token, search);
		if (response.ok) {
			setMedications(response.data.medications);
		} else {
			setMedications([]);
		}
	}

	return [medications, fetchMedicationSuggestions];
}

const Medications = props => {
	const [
		medicationSuggestions,
		fetchMedicationSuggestions,
	] = useMedicationSuggestions();

	const { 
		healthHistory, 
		createMedication, 
		removeMedication, 
		updatePatientHealthHistory,
		submitButtonText,
		token,
		history,
	} = props;
	const {
		evr_medications: medications,
		is_taking_medication: isTakingMedication,
	} = healthHistory;

	return (
		<div>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.medications.header', 'Are you currently taking any medication?') }</h1>
			</div>
			<div className="app-content-block-body">
				{medications.length > 0 && (
					<div className="col">
						<div className="row" style={{justifyContent:'space-around'}}>
							<div className="col30 header">
								{ i18n.t('LABEL.components.forms.medications.medications', 'Medications') }
							</div>
							<div className="col10 header">
								{ i18n.t('LABEL.components.forms.medications.frequency', 'Frequency') }
							</div>
							<div className="col10 header">
								{ i18n.t('LABEL.components.forms.medications.status', 'Status') }
							</div>
							<div className="col20 header">
								{ i18n.t('LABEL.components.forms.medications.source', 'Source') }
							</div>
							<div className="col10" />
						</div>
						{medications.map((medication) => (
							<div key={medication.id} className="row tableBody" style={{justifyContent: 'space-around'}}>
								<div className="col30">{medication.name}</div>
								<div className="col10">{medication.frequency}</div>
								<div className="col10">
									{ medication.current
										? i18n.t('LABEL.components.forms.medications.current', 'Current')
										: i18n.t('LABEL.components.forms.medications.not-current', 'Not Current') }
								</div>
								<div className="col20">{medication.source}</div>
								<div className="col10">
									<button
										type="button"
										onClick={() => removeMedication(medication.id)}
									>
										<IconSVG
											name="delete"
											cssClasses="delete"
										/>
									</button>
								</div>
							</div>
						))}
					</div>
				)}
				<Formik
					initialValues={{
						name: "",
						current: true,
						frequency: "",
					}}
					onSubmit={(values) => {
						createMedication({ medication: values });
					}}
				>
					{({ handleChange, setFieldValue }) => (
						<Form>
							<div className="input-container" style={{marginTop: 20}}>
								<label htmlFor="name">
									{ i18n.t('LABEL.components.forms.medications.medication-label', 'Medication') }
								</label>
								<Field
									name="name"
									onChange={e => {
										fetchMedicationSuggestions(token, e.nativeEvent.target.value);
										handleChange(e);
									}}
									autoComplete="off"
								/>
								{medicationSuggestions.length > 0 && (
									<div className="suggestions-container">
										{medicationSuggestions.map(medication => (
											<div
												className="suggestion-item"
												key={medication.id}
												onMouseDown={() => {
													setFieldValue("name", medication.name); 
												}}
											>
												<div><strong>{medication.name}</strong></div>
											</div>
										))}
									</div>
								)}
							</div>
							<div className="input-container">
								<label htmlFor="frequency">
									{ i18n.t('LABEL.components.forms.medications.frequency-label', 'Frequency') }
								</label>
								<Field name="frequency" />
							</div>
							<div className="button-container">
								<button type="submit">
									{ i18n.t('LABEL.components.forms.medications.add-btn', 'Add Medication') }
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			<div className="app-content-block-footer">
				<div className="button-container" style={{justifyContent: 'space-between'}}>
					<button
						onClick={() => {
							history.goBack();
						}}
						type="button"
						className="default"
						style={{marginTop: 15}}
					>
						{ i18n.t('LABEL.components.forms.medications.back-btn', 'Back') }
					</button>
					<button
						onClick={() => {
							updatePatientHealthHistory({
								medical_history: {
									medications: medications.length > 0,
								},
							});
							props.onSubmit();
						}}
						type="submit"
					>
						{ medications.length > 0 ? submitButtonText : i18n.t('LABEL.components.forms.medications.not-have-medication-btn', 'No, I am not taking any medication.') }
					</button>
				</div>
			</div>
		</div>
	);
};

Medications.propTypes = {
	submitButtonText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	healthHistory: PropTypes.object.isRequired,
	createMedication: PropTypes.func.isRequired,
	removeMedication: PropTypes.func.isRequired,
	updatePatientHealthHistory: PropTypes.func.isRequired,
	token: PropTypes.string.isRequired,
	history: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
	healthHistory: state.patient.health_history,
	token: state.telehealth.token,
});

const mapDispatchToProps = dispatch => ({
	createMedication: medication => dispatch(createMedicationAction(medication)),
	removeMedication: medicationId => dispatch(removeMedicationAction(medicationId)),
	updatePatientHealthHistory: healthHistory => dispatch(updatePatientHealthHistoryAction(healthHistory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Medications);
