import React from 'react';
import { Route } from 'react-router-dom';
import VisitReason from '../scenes/VisitReason';
import ChooseProvider from '../scenes/ChooseProvider';
import PharmacySearch from '../scenes/PharmacySearch';
import HealthProfile from '../scenes/HealthProfile';
import ScheduleCall from '../scenes/ScheduleCall';

const ApplicationRoutes = () => (
	<>
		<Route path="/application/health-profile" component={HealthProfile} />
		<Route path="/application/reason-for-visiting" component={VisitReason} />
		<Route path="/application/pharmacy-search" component={PharmacySearch} />
		<Route path="/application/choose-provider" component={ChooseProvider} />
		<Route path="/application/schedule-call" component={ScheduleCall} />
	</>
);

export default ApplicationRoutes;
