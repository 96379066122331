import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import i18n from 'i18next';

import {
	getBehavioralHistory,
	updateBehavioralHistory,
} from '../../redux/actions/patient';

function Checkbox(props) {
	return (
		<Field name={props.name}>
			{({ field, form }) => {
				return (
					<label className="checkbox-label">
						<input
							type="checkbox"
							{...props}
							checked={field.value && field.value.includes(props.value)}
							onChange={() => {
								if (field.value && field.value.includes(props.value)) {
									const nextValue = field.value.filter((value) => value !== props.value);
									form.setFieldValue(props.name, nextValue);
								} else if (field.value) {
									const nextValue = field.value.concat(props.value);
									form.setFieldValue(props.name, nextValue);
								}
							}}
						/>
						<span className="checkmark" />
						{props.value}
					</label>
				);
			}}
		</Field>
	);
}

const BehavioralHealthHistory = (props) => {
	const { submitButtonText, history } = props;
	const alert = useAlert();

	const behavioralHistory = useSelector(
		(state) => state.patient.behavioral_history
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getBehavioralHistory());
	}, []);

	return (
		<div>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.behavioral.header', 'Behavioral Health History') }</h1>
			</div>

			<Formik
				enableReinitialize
				initialValues={{
					behavioral_health_reasons: behavioralHistory.behavioral_health_reasons.map(
						(o) => {
							if (o.active) {
								return o.condition;
							}
						}
					),
					behavioral_health_description:
						behavioralHistory.behavioral_health_description || '',
					behavioral_mconditions: behavioralHistory.behavioral_mconditions.map(
						(o) => {
							if (o.active) {
								return o.condition;
							}
						}
					),
					hospitalized: behavioralHistory.hospitalized,
					hospitalized_date: behavioralHistory.hospitalized_date || '',
					hospitalized_duration: behavioralHistory.hospitalized_duration || '',
					behavioral_family_history: behavioralHistory.behavioral_family_history.map(
						(o) => {
							if (o.active) {
								return o.condition;
							}
						}
					),
					family_hospitalized: behavioralHistory.family_hospitalized,
					counseling_preference:
						behavioralHistory.counseling_preference || i18n.t('LABEL.components.forms.behavioral.counseling-preference-placeholder', 'No Preference'),
				}}
				validate={(values) => {
					const errors = {};
					if (
						values.behavioral_health_reasons.includes('Other') &&
						values.behavioral_health_description.trim().length === 0
					) {
						errors.behavioral_health_description = i18n.t('LABEL.components.forms.behavioral.health-description-error', 'You must explain your reason when selecting "Other" as an option.');
					}
					if (values.hospitalized && values.hospitalized_date.trim().length === 0) {
						errors.hospitalized_date = i18n.t('LABEL.components.forms.behavioral.hospitalized-date-error', 'You must provide the date you were hospitalized.');
					}
					if (
						values.hospitalized &&
						values.hospitalized_duration.trim().length === 0
					) {
						errors.hospitalized_duration = i18n.t('LABEL.components.forms.behavioral.hospitalized-duration-error', 'You must provide the duration you were hospitalized.');
					}
					return errors;
				}}
				onSubmit={(values) => {
					const bh = { ...values };
					const newBehavioralHistory = {};
					newBehavioralHistory.behavioral_health_reasons = behavioralHistory.behavioral_health_reasons.map(
						(o) => ({
							condition: o.condition,
							active: _.includes(bh.behavioral_health_reasons, o.condition),
						})
					);
					newBehavioralHistory.behavioral_mconditions = behavioralHistory.behavioral_mconditions.map(
						(o) => ({
							condition: o.condition,
							active: _.includes(bh.behavioral_mconditions, o.condition),
						})
					);
					newBehavioralHistory.behavioral_family_history = behavioralHistory.behavioral_family_history.map(
						(o) => ({
							condition: o.condition,
							active: _.includes(bh.behavioral_family_history, o.condition),
						})
					);

					newBehavioralHistory.hospitalized = bh.hospitalized || false;
					if (bh.hospitalized) {
						newBehavioralHistory.hospitalized_date = bh.hospitalized_date || '';
						newBehavioralHistory.hospitalized_duration =
							bh.hospitalized_duration || '';
					}

					newBehavioralHistory.family_hospitalized = bh.family_hospitalized || false;
					newBehavioralHistory.counseling_preference =
						bh.counseling_preference || i18n.t('LABEL.components.forms.behavioral.counseling-preference-placeholder', 'No Preference');
					newBehavioralHistory.behavioral_health_description =
						bh.behavioral_health_description || '';

					dispatch(
						updateBehavioralHistory(newBehavioralHistory, (response) => {
							if (response.ok) {
								props.onSubmit(bh);
							} else {
								const errorMessage = _.get(response.data, 'errors[0]');
								alert.error(errorMessage);
							}
						})
					);
				}}
			>
				{({ values }) => (
					<Form>
						<div className="app-content-block-body">
							<strong>
								{ i18n.t('LABEL.components.forms.behavioral.reason-label', 'What is the reason(s) you are seeking Behavioral Health Care? (check all that apply)') }
							</strong>
							<ErrorMessage name="behavioral_health_reasons">
								{(msg) => <div className="error">{msg}</div>}
							</ErrorMessage>
							<Checkbox name="behavioral_health_reasons" value="Emotional Problem" />
							<Checkbox name="behavioral_health_reasons" value="Relationship Issue" />
							<Checkbox name="behavioral_health_reasons" value="Substance Abuse" />
							<Checkbox name="behavioral_health_reasons" value="Other" />

							{values.behavioral_health_reasons.includes('Other') && (
								<div className="input-container">
									<label>{ i18n.t('LABEL.components.forms.behavioral.reason-explain', 'Please explain') }</label>
									<Field component="textarea" name="behavioral_health_description" />
									<ErrorMessage name="behavioral_health_description">
										{(msg) => <div className="error">{msg}</div>}
									</ErrorMessage>
								</div>
							)}
							<hr />
							<strong>
								{ i18n.t('LABEL.components.forms.behavioral.diagnose-label', 'Have you been diagnosed with any of the following? (check all that apply)') }
							</strong>
							<ErrorMessage name="behavioral_mconditions">
								{(msg) => <div className="error">{msg}</div>}
							</ErrorMessage>
							<Checkbox name="behavioral_mconditions" value="Alcohol Dependence" />
							<Checkbox
								name="behavioral_mconditions"
								value="Obsessive Compulsive Disorder (OCD)"
							/>
							<Checkbox name="behavioral_mconditions" value="Anxiety" />
							<Checkbox name="behavioral_mconditions" value="Panic Attacks" />
							<Checkbox name="behavioral_mconditions" value="Bipolar Disorder" />
							<Checkbox name="behavioral_mconditions" value="Schzophrenia" />
							<Checkbox name="behavioral_mconditions" value="Depression" />
							<hr />
							<label className="checkbox-label">
								<Field name="hospitalized">
									{({ field }) => (
										<input type="checkbox" checked={field.value} {...field} />
									)}
								</Field>
								<span className="checkmark" />
								{ i18n.t('LABEL.components.forms.behavioral.hospitalized-label', 'Have you ever been hospitalized for mental health or substance abuse treatment?') }
							</label>
							{values.hospitalized && (
								<>
									<div className="input-container">
										<label>{ i18n.t('LABEL.components.forms.behavioral.hospitalized-date', 'Hospitalized Date') }</label>
										<Field placeholder="YYYY-MM-DD" name="hospitalized_date" />
										<ErrorMessage name="hospitalized_date">
											{(msg) => <div className="error">{msg}</div>}
										</ErrorMessage>
									</div>
									<div className="input-container">
										<label>{ i18n.t('LABEL.components.forms.behavioral.hospitalized-duration', 'Hospitalized Duration') }</label>
										<Field name="hospitalized_duration" />
										<ErrorMessage name="hospitalized_duration">
											{(msg) => <div className="error">{msg}</div>}
										</ErrorMessage>
									</div>
								</>
							)}
							<hr />
							<strong>
								{ i18n.t('LABEL.components.forms.behavioral.diagnose-family-label', 'Has anyone in your family been diagnosed with any of the following? (check all that apply)') }
							</strong>
							<ErrorMessage name="behavioral_family_history">
								{(msg) => <div className="error">{msg}</div>}
							</ErrorMessage>
							<Checkbox
								type="checkbox"
								name="behavioral_family_history"
								value="Alcohol Dependence"
							/>
							<Checkbox
								type="checkbox"
								name="behavioral_family_history"
								value="Obsessive Compulsive Disorder (OCD)"
							/>
							<Checkbox
								type="checkbox"
								name="behavioral_family_history"
								value="Anxiety"
							/>
							<Checkbox
								type="checkbox"
								name="behavioral_family_history"
								value="Panic Attacks"
							/>
							<Checkbox
								type="checkbox"
								name="behavioral_family_history"
								value="Bipolar Disorder"
							/>
							<Checkbox
								type="checkbox"
								name="behavioral_family_history"
								value="Schzophrenia"
							/>
							<Checkbox
								type="checkbox"
								name="behavioral_family_history"
								value="Depression"
							/>
							<Checkbox
								type="checkbox"
								name="behavioral_family_history"
								value="Unknown"
							/>
							<hr />
							<label className="checkbox-label">
								<Field name="family_hospitalized">
									{({ field }) => (
										<input type="checkbox" checked={field.value} {...field} />
									)}
								</Field>
								<span className="checkmark" />
								{ i18n.t('LABEL.components.forms.behavioral.hospitalized-family-label', 'Has anyone in your family ever been hospitalized for mental health or substance abuse treatment?') }
							</label>
							<hr />
							<div className="input-container">
								<label>
									{ i18n.t('LABEL.components.forms.behavioral.provider-label', 'What is your preference for a provider?') }
								</label>
								<Field as="select" name="counseling_preference">
									<option value="No Preference">
										{ i18n.t('LABEL.components.forms.behavioral.counseling-preference-placeholder', 'No Preference') }
									</option>
									<option value="Male">
										{ i18n.t('LABEL.components.forms.behavioral.counseling-preference-male', 'Male') }
									</option>
									<option value="Female">
										{ i18n.t('LABEL.components.forms.behavioral.counseling-preference-female', 'Female') }
									</option>
								</Field>
							</div>
						</div>
						<div className="app-content-block-footer">
							<div className="button-container" style={{justifyContent: 'space-between'}}>
								<button
									onClick={() => {
										history.goBack();
									}}
									type="button"
									className="default"
									style={{marginTop: 15}}
								>
									{ i18n.t('LABEL.components.forms.behavioral.back-btn', 'Back') }
								</button>
								<button type="submit">{submitButtonText}</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

BehavioralHealthHistory.propTypes = {
	submitButtonText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	// updateBehavioralHistory: PropTypes.func.isRequired,
	// getBehavioralHistory: PropTypes.func.isRequired,
	history: PropTypes.any.isRequired
};

export default BehavioralHealthHistory;
