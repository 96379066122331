import React from 'react';
import { withRouter } from 'react-router-dom';
import HealthProfileRoutes from '../../navigation/HealthProfileRoutes';
import { PageBackground } from '../../components';

const HealthHistory = props => {
	const {
		location: { pathname: currentPath },
		history,
	} = props;

	if (currentPath.endsWith("health-profile") || currentPath.endsWith("health-profile/")) {
		history.push("/application/health-profile/personal-info");
	}
	return (
		<div className="app-content-wrapper">
			<PageBackground
				name="wizard-health-profile"
			/>
			<div className="app-content" style={{ maxWidth: 1000 }}>
				<div className="app-content-block">
					<HealthProfileRoutes />
				</div>
			</div>
		</div>
	);
};

export default withRouter(HealthHistory);
