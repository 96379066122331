import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
	cancelMdliveAppointment,
	fetchMdliveAppointmentInformation as fetchAppointmentInfo,
	fetchUpcomingMdliveAppointments,
} from '../../redux/actions/telehealth';
import { useLoader } from '../../services';
import { PageBackground } from '../../components';
import { IconSVG } from '../../components/icon';
import i18n from 'i18next';

const Appointments = (props) => {
	const { history } = props;
	const [canPollUpcomingAppointments, setCanPollUpcomingAppointments] = useState(
		true
	);
	const dispatch = useDispatch();
	const [Loader, setLoading] = useLoader(false);
	const appointments = useSelector(
		(state) => state.telehealth.existing_mdlive_appt
	);
	const scheduledAppointment = appointments && appointments.appointments[0];
	const mdliveAppointmentInfo = useSelector(
		(state) => state.telehealth.mdlive_appt_information
	);
	const patientId = useSelector((state) => state.telehealth.user.id);

	const videoCallReady = () => {
		const isReady =
			scheduledAppointment &&
			scheduledAppointment.provider_ready &&
			mdliveAppointmentInfo &&
			mdliveAppointmentInfo.url;
		if (!isReady) {
			pollUpcomingAppointments();
		}

		return isReady;
	};

	const pollUpcomingAppointments = () => {
		if (!canPollUpcomingAppointments) {
			return;
		}

		dispatch(fetchUpcomingMdliveAppointments(patientId));
		setCanPollUpcomingAppointments(false);
		setTimeout(() => setCanPollUpcomingAppointments(true), 30000);
	};

	useEffect(() => {
		if (!patientId) {
			history.goBack();
		}

		if (!appointments) {
			setLoading(true);
		} else setLoading(false);
		if (scheduledAppointment && scheduledAppointment.appointment_by === 'video') {
			const appointmentId = scheduledAppointment.id;
			dispatch(fetchAppointmentInfo(appointmentId));
		}
	}, [appointments, scheduledAppointment]);

	const launchVideoCall = () => {
		window.location.href = mdliveAppointmentInfo.url;
	};

	const cancelAppointment = () => {
		dispatch(cancelMdliveAppointment(patientId, scheduledAppointment.id));
		history.goBack();
	};

	const renderContent = () => {
		if (scheduledAppointment) {
			return (
				<>
					<div
						className="row"
						style={{
							borderRadius: 10,
							backgroundColor: '#f2f3f4',
							padding: 10,
							alignItems: 'center',
							width: 'auto',
						}}
					>
						{scheduledAppointment.provider.full_name && (
							<>
								<img
									src={scheduledAppointment.provider.photo_url_absolute}
									alt="provider headshot"
									style={{ width: 68, height: 68 }}
								/>
								<div
									className="col20"
									style={{
										justifyContent: 'flex-start',
										alignItems: 'flex-start',
										marginLeft: 10,
									}}
								>
									<span>{scheduledAppointment.provider.full_name}</span>
									<span>{scheduledAppointment.provider.provider_type}</span>
								</div>
							</>
						)}
						<div className="col40">
							<span>
								{moment(scheduledAppointment.start_date).format('dddd, MMMM Do YYYY')}
							</span>
							<span style={{fontWeight: 'bold'}}>{moment(scheduledAppointment.start_date).format('h:mm A')}</span>
						</div>
						<div className="col30">
							<span>{ i18n.t('LABEL.scenes.appointments.reason-call', 'Reason for call:') }</span>
							<span>{scheduledAppointment.chief_complaint}</span>
						</div>
					</div>

					{scheduledAppointment.appointment_by === 'phone' && (
						<div className="col" style={{fontSize: 18, marginTop: 15, marginBottom: 15}}>
							<span>
								{ i18n.t('LABEL.scenes.appointments.doctor-call-you', '{{doctor}} will be calling you at', { doctor: scheduledAppointment.provider.full_name || 'A doctor' }) }
							</span>
							<span style={{fontWeight: 'bold', fontSize: 22, color: '#000'}}>{scheduledAppointment.customer_call_in_number}</span>
							<span>{ i18n.t('LABEL.scenes.appointments.at-above-time', 'at the above time.') }</span>
						</div>
					)}
					{scheduledAppointment.appointment_by === 'video' && (
						<div className="col" style={{fontSize: 18, marginTop: 15, marginBottom: 15}}>
							{videoCallReady() ? (
								<>
									<p style={{fontSize: '16px', fontWeight: 600, color: '#3b3b3b'}}
									   dangerouslySetInnerHTML={{ __html: i18n.t('LABEL.scenes.appointments.provider-is-ready', 'The Provider is ready! Please click <br/> the button below to join the call') }}>
									</p>
									<div style={{ textAlign: 'center', marginBottom: 15 }}>
										<button type="button" onClick={launchVideoCall} style={{height: 48, marginTop: 10, fontSize: '21px'}}>
											<div className="row" style={{alignItems: 'center'}}>
												<IconSVG
													name="call"
													cssClasses="call"
												/>
											{ i18n.t('LABEL.scenes.appointments.join-video-call', 'Join Video Call') }
											</div>
										</button>
									</div>
								</>
							) : (
								<p style={{fontSize: '16px', fontWeight: 600, color: '#3b3b3b'}}
								   dangerouslySetInnerHTML={{ __html: i18n.t('LABEL.scenes.appointments.please-wait-provider', 'Please wait. When the Provider <br/> is ready, you will be able to join the call') }}>
								</p>
							)}
						</div>
					)}
					<div style={{ textAlign: 'center' }}>
						<button type="button" className="default" onClick={cancelAppointment}>
							{ i18n.t('LABEL.scenes.appointments.cancel-appointment', 'Cancel This Appointment') }
						</button>
					</div>
				</>
			);
		}
		return (
			<div style={{ textAlign: 'center', marginBottom: 50 }}>
				<p style={{fontSize: '16px', fontWeight: 600, color: '#3b3b3b'}}
				   dangerouslySetInnerHTML={{ __html: i18n.t('LABEL.scenes.appointments.no-upcoming-appointments', 'No upcoming appointments. <br/> To schedule one, please press the button below') }}>
				</p>
				<button type="button" onClick={() => history.push('/provider-type')}>
					{ i18n.t('LABEL.scenes.appointments.started-btn', 'Get Started') }
				</button>
			</div>
		);
	};

	if (appointments) {
		return (
			<div className="app">
				<div className="app-header">
					<IconSVG
						name="mdLiveLogo"
						cssClasses="md-live-logo"
					/>
					<IconSVG
						name="logo"
						cssClasses="logo"
					/>
					{scheduledAppointment ? (
						<h1 style={{ textAlign: 'center' }}>
							{ i18n.t('LABEL.scenes.appointments.header-scheduled', 'You have an appointment scheduled!') }
						</h1>
					) : (
						<h1 style={{ textAlign: 'center' }}>
							{ i18n.t('LABEL.scenes.appointments.header-not-scheduled', 'Request an appointment') }
						</h1>
					)}
				</div>
				<div className="app-content-wrapper">
					<PageBackground
						name="appointments"
						cssClass="app-content-wrapper-bg-fullwidth-bottom"
					/>
					<div className="app-content" style={{ maxWidth: 1000 }}>
						<div className="app-content-block">
							<div className="app-content-block-body">{renderContent()}</div>

							<div className="app-content-block-footer" style={{justifyContent: 'space-between', alignItems: 'center'}}>
								<button type="button" className="default" onClick={() => history.goBack()}>
									{ i18n.t('LABEL.scenes.appointments.back-btn', 'Go Back') }
								</button>

								<button
									type="button"
									onClick={() => {
										const domain = window.location.origin.replace('telehealth.', '');
										window.location = `${domain}/member/v1`;
									}}
								>
									{ i18n.t('LABEL.scenes.appointments.return-btn', 'Return to Member Center') }
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return <Loader />;
};

export default Appointments;
