import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { useAlert } from 'react-alert';
import Initicon from 'react-initicon';
import { PageBackground } from '../../components';
import { IconSVG } from '../../components/icon';
import {
	fetchUpcomingMdliveAppointments,
	fetchProviders as fetchProvidersAction,
	fetchToken as fetchTokenAction,
	fetchLuckyOrangeCode as fetchLuckyOrange,
	setToken,
	setUserId,
	resetAllState,
} from '../../redux/actions/telehealth';
import {
	copyPatientToProfile,
	fetchPatientProfilesForFamily,
	fetchPatientHealthHistory,
	setCurrentPatient,
} from '../../redux/actions/patient';

import { useLoader } from '../../services';
import i18n from 'i18next';

const SelectPatient = props => {
	const alert = useAlert();
	const [Loader, setLoading] = useLoader(false);
	const {
		cookies,
		history,
		fetchToken,
		fetchLuckyOrangeCode,
		copyPatientToProfile,
		setCurrentPatient,
		fetchUpcomingMdliveAppointments,
		fetchPatientProfiles,
		fetchPatientHealthHistory,
		familyMembers,
		fetchProviders,
		mdliveAppointment,
		patientAddressState,
		setToken,
		setUserId,
		users,
		resetAllState,
	} = props;

	const token = useSelector(state => state.telehealth.token);
	useEffect(() => {
		if (!token) {
			setLoading(true);
			const browserBearerObject = cookies.get('auth_token');
			/*
			if (!browserBearerObject && process.env.NODE_ENV === 'production') {
				showError('Due to inactivity, your session expired. Please log back in to continue.');
			}
			*/
			fetchToken(browserBearerObject, (success, users) => {
				const isUsersHasErrors = users && users.some((user) => user && user.errors && user.errors.length);
				if (success && users && users.length && !isUsersHasErrors) {
					fetchPatientProfiles(users, () => {
						setLoading(false);
					});
				} else if (!success || isUsersHasErrors) {
					showError(i18n.t('LABEL.scenes.select-patient.something-unexpected-happened', 'Something unexpected happened please ask support team to help you.'));
				} else if (success && (!users || !users.length)) {
					showError(i18n.t('LABEL.scenes.select-patient.no-active-members', 'Something unexpected happened please ask support team to help you.'));
				} else {
					setLoading(false);
				}
			});
			fetchLuckyOrangeCode(browserBearerObject, (success, settings) => {
				if (success && settings && settings.IsTenantVisible && settings.Value && settings.Value.trim()) {
					const code =  settings.Value.trim();
					const script = document.createElement('script');
					script.type = 'text/javascript';
					script.src = `https://tools.luckyorange.com/core/lo.js?site-id=${code}`;
					script.async = true;
					script.defer = true;
					script.id = 'LuckyOrangeTracker';
					script.onload = () => {
					if (window.LO || window.LOQ) {
						console.log('Lucky Orange has been downloaded and is ready to be used');
					}
					};
					document.head.appendChild(script);
				}
			});
		} else if (patientAddressState) {
			// fetchProviders(patientAddressState);
		}
		// resetAllState();
	}, [token, patientAddressState]);

	const showError = errorText => {
		setLoading(false);
		alert.error(
			errorText,
			{
				timeout: 30000,
				onClose: () => {
					const domain = window.location.origin.replace('telehealth.', '');
					window.location.replace(`${domain}/member/v1`);
				}
			}
		);
	}

	const setSelectedPatientAuthToken = patientId => {
		const currentPatient = users.find(user => user.user.id === patientId);
		const patientJwt = currentPatient.jwt;
		setToken(patientJwt);
	};

	const setSelectedPatient = async patient => {
		copyPatientToProfile(patient);
		setCurrentPatient(patient.id);
		setUserId(patient.id);
		setSelectedPatientAuthToken(patient.id);
		fetchPatientHealthHistory(patient.id);
		fetchUpcomingMdliveAppointments(patient.id);
		history.push('/appointments');
	};

	return (
		<div className="app">
			<Loader />
			<div className="app-header">
				<IconSVG
					name="mdLiveLogo"
					cssClasses="md-live-logo"
				/>
				<IconSVG
					name="logo"
					cssClasses="logo"
				/>
				<h1 style={{ textAlign: 'center' }}>
					{ i18n.t('LABEL.scenes.select-patient.header', 'Who needs help today?') }
				</h1>
			</div>
			<div className="app-content-wrapper">
				<PageBackground
					name="select-patient"
				/>
				<div className="app-content-container">
					<div className="app-content list">
						{familyMembers.length > 0 &&
						familyMembers.map((patient, index) => (
							<div
								key={`patient-${patient.fullName}-${patient.id}`}
								onClick={() => setSelectedPatient(patient)}
								className="app-content-block one-fourth"
								style={
									familyMembers.length < 4 && index === familyMembers.length - 1
										? { marginRight: 0, marginBottom: 0, padding: 40 }
										: {padding: 40}
								}
							>
								<Initicon
									size={100}
									text={patient.fullname}
									seed={patient.id}
									single={false}
									saturation="50%"
									brightness="50%"
								/>
								<h1 className="app-content-block-fullname">{patient.fullname}</h1>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

SelectPatient.propTypes = {
	cookies: PropTypes.shape({
		get: PropTypes.func.isRequired,
	}).isRequired,
};

const mapStateToProps = state => ({
	currentPatientId: state.patient.currentPatientId,
	familyMembers: _.get(state, 'patient.family_members', []),
	patientAddressState: _.get(state, 'patient.patient_profile.state.abbrev', ''),
	users: state.telehealth.users,
	mdliveAppointment: state.telehealth.existing_mdlive_appt,
});

const mapDispatchToProps = dispatch => ({
	fetchToken: (bearer, cb) => dispatch(fetchTokenAction(bearer, cb)),
	fetchLuckyOrangeCode: (bearer, cb) => dispatch(fetchLuckyOrange(bearer, cb)),
	fetchProviders: patientAddressState =>
		dispatch(fetchProvidersAction(patientAddressState)),
	fetchPatientProfiles: (patients, cb) =>
		dispatch(fetchPatientProfilesForFamily(patients, cb)),
	fetchPatientHealthHistory: id => dispatch(fetchPatientHealthHistory(id)),
	setCurrentPatient: id => dispatch(setCurrentPatient(id)),
	copyPatientToProfile: patient => dispatch(copyPatientToProfile(patient)),
	setToken: token => dispatch(setToken(token)),
	setUserId: userId => dispatch(setUserId(userId)),
	fetchUpcomingMdliveAppointments: id => dispatch(fetchUpcomingMdliveAppointments(id)),
	resetAllState: () => dispatch(resetAllState()),
});

export default withCookies(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectPatient))
);
