import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import {
	createAllergy as createAllergyAction,
	removeAllergy as removeAllergyAction,
	updatePatientHealthHistory as updatePatientHealthHistoryAction,
} from '../../redux/actions/patient';
import api from '../../services/api';
import { IconSVG } from '../icon';
import i18n from 'i18next';

const useAllergySuggestions = () => {
	const [allergies, setAllergies] = useState([]);

	const fetchAllergySuggestions = async (token, search) => {
		const response = await api.fetchAllergySuggestions(token, search);
		if (response.ok) {
			setAllergies(response.data.allergies);
		} else {
			setAllergies([]);
		}
	}

	return [allergies, fetchAllergySuggestions];
}

const Allergies = props => {
	const [
		allergySuggestions,
		fetchAllergySuggestions,
	] = useAllergySuggestions();

	const { 
		healthHistory, 
		createAllergy, 
		removeAllergy, 
		updatePatientHealthHistory,
		submitButtonText,
		token,
		history,
	} = props;
	const {
		allergies,
		has_allergies: hasAllergies,
	} = healthHistory;

	return (
		<div>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.allergies.header', 'Do you have any allergies or drug sensitivities?') }</h1>
			</div>
			<div className="app-content-block-body">
				{allergies.length > 0 && (
					<div className="col">
						<div className="row" style={{justifyContent:'space-around'}}>
							<div className="col30 header">
								{ i18n.t('LABEL.components.forms.allergies.allergies', 'Allergies') }
							</div>
							<div className="col10 header">
								{ i18n.t('LABEL.components.forms.allergies.severity', 'Severity') }
							</div>
							<div className="col10 header">
								{ i18n.t('LABEL.components.forms.allergies.reaction', 'Reaction') }
							</div>
							<div className="col20 header">
								{ i18n.t('LABEL.components.forms.allergies.source', 'Source') }
							</div>
							<div className="col10" />
						</div>
						{allergies.map((allergy) => (
							<div key={allergy.id} className="row tableBody" style={{justifyContent: 'space-around'}}>
								<div className="col30">{allergy.name}</div>
								<div className="col10">{allergy.severity}</div>
								<div className="col10">{allergy.reaction}</div>
								<div className="col20">{allergy.source}</div>
								<div className="col10">
									<button
										type="button"
										onClick={() => removeAllergy(allergy.id)}
									>
										<IconSVG
											name="delete"
											cssClasses="delete"
										/>
									</button>
								</div>
							</div>
						))}
					</div>
				)}
				<Formik
					initialValues={{
						name: "",
						severity: "",
						reaction: ""
					}}
					onSubmit={(values) => {
						createAllergy({ allergy: values });
					}}
				>
					{({ handleChange, setFieldValue }) => (
						<Form>
							<div className="input-container" style={{marginTop: 20}}>
								<label htmlFor="name">
									{ i18n.t('LABEL.components.forms.allergies.allergy-label', 'Allergy') }
								</label>
								<Field
									name="name"
									onChange={e => {
										fetchAllergySuggestions(token, e.nativeEvent.target.value);
										handleChange(e);
									}}
									autoComplete="off"
								/>
								{allergySuggestions.length > 0 && (
									<div className="suggestions-container">
										{allergySuggestions.map(allergy => (
											<div
												className="suggestion-item"
												key={allergy.id}
												onMouseDown={() => {
													setFieldValue("name", allergy.name); 
												}}
											>
												<div><strong>{allergy.name}</strong></div>
											</div>
										))}
									</div>
								)}
							</div>
							<div className="input-container">
								<label htmlFor="severity">
									{ i18n.t('LABEL.components.forms.allergies.severity-label', 'Severity') }
								</label>
								<Field name="severity" />
							</div>
							<div className="input-container">
								<label htmlFor="reaction">
									{ i18n.t('LABEL.components.forms.allergies.reaction-label', 'Reaction') }
								</label>
								<Field name="reaction" />
							</div>
							<div className="button-container">
								<button type="submit">
									{ i18n.t('LABEL.components.forms.allergies.add-btn', 'Add Allergy') }
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			<div className="app-content-block-footer">
				<div className="button-container" style={{justifyContent: 'space-between'}}>
					<button
						onClick={() => {
							history.goBack();
						}}
						type="button"
						className="default"
						style={{marginTop: 15}}
					>
						{ i18n.t('LABEL.components.forms.allergies.back-btn', 'Back') }
					</button>
					<button
						onClick={() => {
							updatePatientHealthHistory({
								medical_history: {
									allergies: allergies.length > 0,
								},
							});
							props.onSubmit();
						}}
						type="submit"
					>
						{ allergies.length > 0 ? submitButtonText : i18n.t('LABEL.components.forms.allergies.not-have-allergy-btn', 'No, I do not have any allergies.') }
					</button>
				</div>
			</div>
		</div>
	);
};

Allergies.propTypes = {
	submitButtonText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	healthHistory: PropTypes.object.isRequired,
	createAllergy: PropTypes.func.isRequired,
	removeAllergy: PropTypes.func.isRequired,
	updatePatientHealthHistory: PropTypes.func.isRequired,
	token: PropTypes.string.isRequired,
	history: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	healthHistory: state.patient.health_history,
	token: state.telehealth.token,
});

const mapDispatchToProps = dispatch => ({
	createAllergy: allergy => dispatch(createAllergyAction(allergy)),
	removeAllergy: allergyId => dispatch(removeAllergyAction(allergyId)),
	updatePatientHealthHistory: healthHistory => dispatch(updatePatientHealthHistoryAction(healthHistory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Allergies);