import { combineReducers } from 'redux';

import telehealth from './telehealth';
import user from './user';
import patient from './patient';

export default combineReducers({
	telehealth,
	user,
	patient,
});
