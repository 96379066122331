import _ from 'lodash';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
	submitMdliveAppointment,
	fetchUpcomingMdliveAppointments,
} from '../../redux/actions/telehealth';
import { IconSVG } from '../icon';
import { isMobile } from 'react-device-detect';
import i18n from 'i18next';

function Checkbox({ field, type, checked }) {
	return <input {...field} type="checkbox" checked={checked} />;
}

const ScheduleCall = ({ history }) => {
	const alert = useAlert();
	const patient = useSelector((state) => state.patient.patient_profile);
	const patientInitials = `${_.get(patient, 'first_name', '').charAt(0)}${_.get(
		patient,
		'last_name',
		''
	).charAt(0)}`.toUpperCase();
	const provider = useSelector((state) =>
		_.get(state, 'telehealth.selected_provider.providerDetails')
	);
	const selectedProviderId = useSelector((state) =>
		_.get(state.telehealth, 'selected_provider_id', null)
	);
	const providerAvailability = useSelector((state) =>
		_.get(state, 'telehealth.selected_provider.availabilityDetails')
	);
	const privacyPolicyText = useSelector(
		(state) => state.patient.patient_profile.affiliation.privacy_policy_text
	);
	const informedConsentText = useSelector(
		(state) => state.patient.patient_profile.affiliation.informed_consent_text
	);
	const imageId = useSelector((state) => _.get(state, 'telehealth.image.id'));
	// const requestedAppointment = useSelector(
	// 	state => state.telehealth.requestedAppointment
	// );
	// // This is needed for the MDLIVE call request body
	// const requestedAppointmentTime = requestedAppointment.time
	// 	? moment(requestedAppointment.time).unix()
	// 	: null;
	const chiefComplaint =
		useSelector((state) => state.telehealth.chief_complaint) || {};
	const chiefComplaintComment = useSelector(
		(state) => state.telehealth.chief_complaint_comment
	);
	const providerType = useSelector(
		(state) =>
			state.telehealth.selected_provider_type &&
			state.telehealth.selected_provider_type.name
	);
	const patientSelectedState = useSelector(
		(state) => state.patient.selected_state
	);
	let providerTypeId;
	if (providerType === 'Medical Visit' && patient.is_minor) providerTypeId = 2;
	else if (providerType === 'Medical Visit') providerTypeId = 3;
	else if (providerType === 'Therapy Counseling') providerTypeId = 5;
	else if (providerType === 'Psychiatry') providerTypeId = 6;
	const dispatch = useDispatch();

	const mdliveRequestBody = {
		appointment: {
			provider_type_id: providerTypeId,
			provider_id: provider ? provider.id : null,
			state_abbrev: patientSelectedState,
			survey_answer: '',
			customer_call_in_number: patient.phone,
			appointment_method: '',
			start_time: provider ? moment() : null,
			concept_id: chiefComplaint.concept_id,
			chief_complaint: chiefComplaint.title,
			chief_complaint_comments: chiefComplaintComment,
			agrees_to_informed_consent: false,
			agrees_to_privacy_agreement: false,
			consent_user_initials: '',
			file_id: imageId,
		},
	};

	const setRequestValues = (values) => {
		if (values.schedule_call_return_contact_number)
			mdliveRequestBody.appointment.customer_call_in_number =
				values.schedule_call_return_contact_number;
		if (values.schedule_call_appointment_type)
			mdliveRequestBody.appointment.appointment_method =
				values.schedule_call_appointment_type;
		if (values.schedule_call_consent_user_initials)
			mdliveRequestBody.appointment.consent_user_initials =
				values.schedule_call_consent_user_initials;
		if (values.schedule_call_agrees_to_informed_consent)
			mdliveRequestBody.appointment.agrees_to_informed_consent =
				values.schedule_call_agrees_to_informed_consent;
		if (values.schedule_call_agrees_to_privacy_agreement)
			mdliveRequestBody.appointment.agrees_to_privacy_agreement =
				values.schedule_call_agrees_to_privacy_agreement;
		if (values.schedule_call_survey_question)
			mdliveRequestBody.appointment.survey_answer =
				values.schedule_call_survey_question;
		if (values.schedule_call_appointment_time)
			mdliveRequestBody.appointment.start_time = moment(
				values.schedule_call_appointment_time
			).unix();
	};

	return (
		<div>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.schedule-call.header', 'Schedule Call') }</h1>
			</div>
			<div className="app-content-block-body">
				<div
					className="row"
					style={{
						borderRadius: 10,
						backgroundColor: '#f2f3f4',
						padding: 10,
						alignItems: 'center',
						width: 'auto',
					}}
				>
					{!provider && (
						<div className="doctor-image-wrapper">
							<IconSVG
								name="doctor"
								cssClasses="doctor-image"
							/>
						</div>
					)}

					{provider && (
						<>
							<img
								src={provider.photo_url_absolute}
								alt="Provider Profile"
								className="doctor-image"
							/>
							<div
								className="col30"
								style={{
									justifyContent: 'flex-start',
									alignItems: 'flex-start',
									marginLeft: 10,
								}}
							>
								<h3>{ i18n.t('LABEL.components.forms.schedule-call.selected-provider', 'Selected Provider:') }</h3>
								<span>{provider.fullname}</span>
								<span>{provider.specialty}</span>
							</div>
						</>
					)}

					{chiefComplaint && (
						<div className="col">
							<h3>{ i18n.t('LABEL.components.forms.schedule-call.reason', 'Reason For Call:') }</h3>
							<span style={{ display: 'block' }}>{chiefComplaint.title}</span>
							<span style={{ display: 'block' }}>{chiefComplaint.alternate_term}</span>
							<span style={{ display: 'block' }}>
								{ i18n.t('LABEL.components.forms.schedule-call.patient-details', 'Patient details:') }
								{' '}
								{chiefComplaintComment}
							</span>
						</div>
					)}
				</div>

				<Formik
					initialValues={{
						schedule_call_return_contact_number: patient.phone,
						schedule_call_appointment_type: 'phone',
					}}
					validate={(values) => {
						const errors = {};
						if (!values.schedule_call_return_contact_number)
							errors.schedule_call_return_contact_number = i18n.t('LABEL.components.forms.schedule-call.phone-error-msg', 'You must enter a callback phonenumber');
						if (selectedProviderId !== -1 && !values.schedule_call_appointment_time)
							errors.schedule_call_appointment_time = i18n.t('LABEL.components.forms.schedule-call.appointment-time-error-msg', 'You must select a time');
						if (
							!values.schedule_call_consent_user_initials ||
							values.schedule_call_consent_user_initials !== patientInitials
						)
							errors.schedule_call_consent_user_initials = i18n.t('LABEL.components.forms.schedule-call.patient-error-msg', 'You must enter the patient\'s initials {{patient}} to continue',
																				{ patient: patientInitials });
						if (!values.schedule_call_agrees_to_informed_consent)
							errors.schedule_call_agrees_to_informed_consent = i18n.t('LABEL.components.forms.schedule-call.consent-error-msg', 'You must check the box to continue');
						if (!values.schedule_call_agrees_to_privacy_agreement)
							errors.schedule_call_agrees_to_privacy_agreement = i18n.t('LABEL.components.forms.schedule-call.policy-error-msg', 'You must check the box to continue');
						if (!values.schedule_call_survey_question)
							errors.schedule_call_survey_question = i18n.t('LABEL.components.forms.schedule-call.survey-error-msg', 'You must answer the survey question to continue');

						return errors;
					}}
					onSubmit={(values) => {
						setRequestValues(values);
						dispatch(
							submitMdliveAppointment(patient.id, mdliveRequestBody, (response) => {
								if (response.ok) {
									dispatch(fetchUpcomingMdliveAppointments(patient.id));
									alert.success(i18n.t('LABEL.components.forms.schedule-call.appointment-success-msg', 'Appointment Scheduled!'), {
										timeout: 5000, // custom timeout just for this one alert
										onClose: () => {
											history.push('/appointments');
										},
									});
								} else {
									const providerError = _.get(response.data, 'errors.provider', null);
									const cardError = _.get(response.data, 'error_codes', []).filter(
										(code) => code === 'no_credit_card_information'
									);
									const otherError = _.get(response.data, 'errors.base', []);

									if (cardError && cardError.length > 0) {
										alert.error(
											i18n.t('LABEL.components.forms.schedule-call.scheduling-error-msg', 'We are sorry, there was a problem scheduling your appointment.')
										);
									} else if (providerError) {
										alert.error(i18n.t('LABEL.components.forms.schedule-call.provider-error-msg', 'We are sorry, the provider {{error}}', { error: providerError }), {
											timeout: 5000, // custom timeout just for this one alert
											onClose: () => {
												history.push('/application/choose-provider');
											},
										});
									} else if (otherError.length) {
										alert.error(
											i18n.t('LABEL.components.forms.schedule-call.other-error-msg',
												'{{error}}',
												{ error: otherError[0] }),
											{
												timeout: 5000, // custom timeout just for this one alert
												onClose: () => {
													history.push('/application/choose-provider');
												},
											}
										);
									} else {
										alert.error(
											i18n.t('LABEL.components.forms.schedule-call.mdlive-error-msg', 'We’re temporarily having difficulty scheduling your appointment.'),
											{
												timeout: 5000, // custom timeout just for this one alert
												onClose: () => {
													history.push('/application/choose-provider');
												},
											}
										);
									}
								}
							})
						);
					}}
				>
					{({ values, errors, handleBlur, handleChange, touched }) => (
						<Form style={{ marginTop: 20 }}>
							<div className="row">
								<div
									className="input-container"
									style={{ width: '50%', flex: 1, marginRight: 10 }}
								>
									{touched.schedule_call_return_contact_number &&
									errors.schedule_call_return_contact_number ? (
											<label
												htmlFor="schedule_call_return_contact_number"
												className="error"
											>
												{errors.schedule_call_return_contact_number}
											</label>
										) : (
											<label htmlFor="schedule_call_return_contact_number">
												{ i18n.t('LABEL.components.forms.schedule-call.phone-label', 'Phone Number') }
											</label>
										)}
									<Field
										name="schedule_call_return_contact_number"
										autoComplete="off"
										onBlur={handleBlur}
										onChange={handleChange}
									/>
								</div>
								<div className="input-container" style={{ width: '50%', flex: 1 }}>
									<label>{ i18n.t('LABEL.components.forms.schedule-call.appointment-label', 'Appointment Type') }</label>
									<Field name="schedule_call_appointment_type" as="select">
										<option value="phone">{ i18n.t('LABEL.components.forms.schedule-call.phone', 'Phone') }</option>
										<option value="video">{ i18n.t('LABEL.components.forms.schedule-call.video', 'Video') }</option>
									</Field>
								</div>
							</div>
							{provider && (
								<div className="input-container" style={ isMobile ? {} : { width: '50%', margin: 0 }}>
									{errors.schedule_call_appointment_time ? (
										<label htmlFor="schedule_call_appointment_time" className="error">
											{errors.schedule_call_appointment_time}
										</label>
									) : (
										<label htmlFor="schedule_call_appointment_time">
											{ i18n.t('LABEL.components.forms.schedule-call.appointment-time-label', 'What time would you like to schedule the call for?') }
										</label>
									)}
									<Field
										name="schedule_call_appointment_time"
										as="select"
										onBlur={handleBlur}
										onChange={handleChange}
									>
										<option>{ i18n.t('LABEL.components.forms.schedule-call.appointment-time-placeholder', 'Please Select a time') }</option>
										{providerAvailability &&
											providerAvailability.available_hours.map((hour) => {
												return (
													<option value={hour.timeslot}>
														{moment(hour.timeslot).format('h:mm A')}
													</option>
												);
											})}
									</Field>
									{/* <DatePicker
									selected={startDate}
									onChange={date => setStartDate(date)}
									showTimeSelect
									includeTimes={[
										setHours(setMinutes(new Date(), 0), 17),
										setHours(setMinutes(new Date(), 30), 18),
										setHours(setMinutes(new Date(), 30), 19),
										setHours(setMinutes(new Date(), 30), 17)
									]}
									dateFormat="MMMM d, yyyy h:mm aa"
								/> */}
									<ErrorMessage
										name="schedule_call_appointment_type"
										render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
									/>
								</div>
							)}
							<div className="input-container">
								{errors.schedule_call_consent_user_initials ? (
									<label htmlFor="schedule_call_consent_user_initials" className="error">
										{errors.schedule_call_consent_user_initials}
									</label>
								) : (
									<label htmlFor="schedule_call_consent_user_initials">
										{ i18n.t('LABEL.components.forms.schedule-call.patient-label',
												'Please enter the patient\'s initials ({{patient}}) to indicate that you are giving consent for treatment',
												{ patient: patientInitials }) }
									</label>
								)}
								<Field
									name="schedule_call_consent_user_initials"
									onBlur={handleBlur}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								{errors.schedule_call_agrees_to_informed_consent && (
									<label
										htmlFor="schedule_call_agrees_to_informed_consent"
										className="error"
									>
										{errors.schedule_call_agrees_to_informed_consent}
									</label>
								)}
								<label className="checkbox-label">
									<Field
										name="schedule_call_agrees_to_informed_consent"
										as="checkbox"
										component={Checkbox}
										checked={values.schedule_call_agrees_to_informed_consent}
									/>
									<span className="checkmark" />
									{ i18n.t('LABEL.components.forms.schedule-call.consent-label', 'I agree and give informed consent') }
								</label>
								<Link to={{ pathname: '/read', state: { text: informedConsentText } }}>
									{ i18n.t('LABEL.components.forms.schedule-call.read-consent', 'Read Informed Consent') }
								</Link>
							</div>
							<div className="input-container">
								{errors.schedule_call_agrees_to_privacy_agreement && (
									<label
										htmlFor="schedule_call_agrees_to_privacy_agreement"
										className="error"
									>
										{errors.schedule_call_agrees_to_privacy_agreement}
									</label>
								)}
								<label className="checkbox-label">
									<Field
										name="schedule_call_agrees_to_privacy_agreement"
										as="checkbox"
										component={Checkbox}
										checked={values.schedule_call_agrees_to_privacy_agreement}
									/>
									<span className="checkmark" />
									{ i18n.t('LABEL.components.forms.schedule-call.policy-label', 'I have read and agree to the privacy policy') }
								</label>
								<Link to={{ pathname: '/read', state: { text: privacyPolicyText } }}>
									{ i18n.t('LABEL.components.forms.schedule-call.read-policy', 'Read Privacy Policy') }
								</Link>
							</div>
							<div className="input-container">
								{errors.schedule_call_survey_question ? (
									<label htmlFor="schedule_call_survey_question" className="error">
										{errors.schedule_call_survey_question}
									</label>
								) : (
									<label htmlFor="schedule_call_survey_question">
										{ i18n.t('LABEL.components.forms.schedule-call.survey-label', 'Where would you have gone if you were unable to use this Telehealth feature?') }
									</label>
								)}
								<Field as="select" name="schedule_call_survey_question">
									<option>{ i18n.t('LABEL.components.forms.schedule-call.survey-placeholder', 'Please select an answer') }</option>
									<option value="Emergency Room">{ i18n.t('LABEL.components.forms.schedule-call.emergency-room', 'Emergency Room') }</option>
									<option value="Primary Care">{ i18n.t('LABEL.components.forms.schedule-call.primary-care', 'Primary Care') }</option>
								</Field>
								<ErrorMessage
									name="schedule_call_survey_question"
									render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
								/>
							</div>
							<div className="app-content-block-footer">
								<div
									className="button-container"
									style={{ justifyContent: 'space-between' }}
								>
									<button type="button" className="default" onClick={() => history.goBack()} style={{marginTop: 15}}>
										{ i18n.t('LABEL.components.forms.schedule-call.back-btn', 'Back') }
									</button>
									<button type="submit">{ i18n.t('LABEL.components.forms.schedule-call.submit-btn', 'Submit') }</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

ScheduleCall.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default withRouter(ScheduleCall);
