import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import Reactotron from '../config/ReactotronConfig';

import reduxPersistConfiguration from '../config/reduxPersist';
import rootSaga from './sagas';
import rootReducer from './reducers';

// creates the store
export default onCompletion => {
	/* ------------- Saga Middleware ------------- */

	// create our new saga monitor
	const sagaMonitor = Reactotron.createSagaMonitor();
	const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

	/* ------------- Assemble Middleware ------------- */

	const persistedReducer = persistReducer(
		reduxPersistConfiguration,
		rootReducer
	);
	const store = createStore(
		persistedReducer,
		compose(applyMiddleware(sagaMiddleware), Reactotron.createEnhancer())
	);
	const persistor = persistStore(store, null, onCompletion);

	// kick off root saga
	sagaMiddleware.run(rootSaga);
  
	if (process.env.NODE_ENV !== 'production') {
		if (module.hot) {
			module.hot.accept('./reducers', () => {
				store.replaceReducer(rootReducer);
			});
		}
	}

	return { store, persistor };
};
