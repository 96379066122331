import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {
	PersonalInfoForm, 
	HealthConditionsForm, 
	MedicationsForm, 
	AllergiesForm, 
	SurgeriesForm,
	BehavioralForm
} from '../components/forms';

const HealthProfileRoutes = ({history, selectedProviderType = {}}) => {
	const navigateToNextPage = (route) => {
		history.push(route);
	};
	const isMedicalVisit = selectedProviderType && selectedProviderType.name === "Medical Visit";
	return (
		<>
			<Route
				path="/application/health-profile/personal-info"
				component={props => (
					<PersonalInfoForm
						{...props}
						submitButtonText="Next"
						history={history}
						onSubmit={() => navigateToNextPage("/application/health-profile/health-conditions")}
					/>
				)}
			/>
			<Route
				path="/application/health-profile/health-conditions"
				component={props => (
					<HealthConditionsForm
						{...props}
						submitButtonText="Next"
						history={history}
						onSubmit={() => navigateToNextPage("/application/health-profile/medications")}
					/>
				)}
			/>
			<Route
				path="/application/health-profile/medications"
				component={props => (
					<MedicationsForm
						{...props}
						submitButtonText="Next"
						history={history}
						onSubmit={() => navigateToNextPage("/application/health-profile/allergies")}
					/>
				)}
			/>
			<Route
				path="/application/health-profile/allergies"
				component={props => (
					<AllergiesForm
						{...props}
						submitButtonText="Next"
						history={history}
						onSubmit={() => navigateToNextPage("/application/health-profile/surgeries")}
					/>
				)}
			/>
			<Route
				path="/application/health-profile/surgeries"
				component={props => (
					<SurgeriesForm
						{...props}
						submitButtonText="Next"
						history={history}
						onSubmit={() => navigateToNextPage(isMedicalVisit ? "/application/pharmacy-search" : "/application/health-profile/behavioral")}
					/>
				)}
			/>
			<Route
				path="/application/health-profile/behavioral"
				component={props => (
					<BehavioralForm
						{...props}
						submitButtonText="Next"
						history={history}
						onSubmit={() => navigateToNextPage("/application/pharmacy-search")}
					/>
				)}
			/>
		</>
	);
}

const mapStateToProps = state => ({
	selectedProviderType: state.telehealth.selected_provider_type,
});

export default connect(mapStateToProps)(withRouter(HealthProfileRoutes));
