import React from 'react';
import { withRouter } from 'react-router-dom';
import i18n from 'i18next';

const LegalText = props => {
	const {
		history,
		location: {
			state: { text },
		},
	} = props;

	return (
		<>
			<button type="button" onClick={() => history.goBack()}>
				{ i18n.t('LABEL.scenes.legal-text.back-btn', 'Go Back') }
			</button>
			<div dangerouslySetInnerHTML={{ __html: text }} />
		</>
	);
};

export default withRouter(LegalText);
