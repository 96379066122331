import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import i18n from 'i18next';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import {
	fetchSpecificProvider,
	setSelectedProvider,
} from '../../redux/actions/telehealth';
import { useLoader } from '../../services';
import { isMobile } from 'react-device-detect';

const ProviderListItem = (provider, history) => {
	const dispatch = useDispatch();
	const [appointmentDate = new Date(), setAppointmentDate] = useState([]);
	const [Loader, setLoading] = useLoader(false);
	const providerDetails = useSelector((state) =>
		_.get(state, 'telehealth.selected_provider.providerDetails')
	);
	const availabilityDetails = useSelector((state) =>
		_.get(state, 'telehealth.selected_provider.availabilityDetails')
	);

	const formattedDate = moment(provider.next_appt_available_date).format(
		'dddd, MMMM D, YYYY'
	);
	const formattedDay = moment(provider.next_appt_available_date).format(
		'dddd'
	);
	const mobileFormattedDate = moment(provider.next_appt_available_date).format(
		'MMMM D, YYYY'
	);

	useEffect(() => {
		if (providerDetails) {
			setLoading(false);
			if (Array.isArray(appointmentDate)) {
				setAppointmentDate(new Date());
			}
		}
	}, [providerDetails]);

	const getProviderDetails = (selectedDate = null) => {
		// show loader
		setLoading(true);
		if (!Boolean(selectedDate)) {
			dispatch(setSelectedProvider(provider));
		}
		const specificDate = selectedDate || appointmentDate;
		// dispatch the redux action
		dispatch(fetchSpecificProvider(provider.id, specificDate));
	};

	const setProvider = () => {
		history.push('/application/schedule-call');
	};

	if (!providerDetails || providerDetails.id !== provider.id) {
		return (
			<div
				className="row"
				style={{
					justifyContent: 'space-around',
					alignItems: 'center',
					width: '95%',
					paddingLeft: isMobile ? 10 : 24,
					paddingRight: isMobile ? 10 : 24,
				}}
			>
				<img
					src={provider.photo_url_absolute}
					alt="Provider Profile"
					style={{ width: 60, height: 60 }}
				/>
				<div 
					className="col" 
					style={{ width: '40%', marginLeft: isMobile ? 10 : 15 } }>
					<h1>{provider.fullname}</h1>
					<div>{provider.specialty}</div>
				</div>
				<div className="col">
					{provider.next_appt_available_date && (
						<p>
							{ i18n.t('LABEL.components.forms.choose-provider.next-available', 'Next available:') }
							<br />
							{isMobile ? (
								<div>	
									{formattedDay},
									<br />
									{mobileFormattedDate}
								</div>
							) : (
								<div>{formattedDate}</div>
							)}
						</p>
					)}
					{!provider.next_appt_available_date && (<p>{ i18n.t('LABEL.components.forms.choose-provider.not-available', 'No Availability today') }</p>) }
					{isMobile && (
						<p>
							<button 
								type="button" 
								style={{ fontSize: 11, paddingLeft: 5, paddingRight: 5 }}
								onClick={() => getProviderDetails()}>
								{!provider.next_appt_available_date
									? i18n.t('LABEL.components.forms.choose-provider.view-details-btn', 'View provider details')
									: i18n.t('LABEL.components.forms.choose-provider.see-all-btn', 'See all available times') }
							</button>
						</p>
					)}
				</div>
				{!isMobile && (
					<div className="col">
						<p>
							<button type="button" onClick={() => getProviderDetails()}>
								{!provider.next_appt_available_date
									? i18n.t('LABEL.components.forms.choose-provider.view-details-btn', 'View provider details')
									: i18n.t('LABEL.components.forms.choose-provider.see-all-btn', 'See all available times') }
							</button>
						</p>
					</div>
				)}
			</div>
		);
	}

	if (Array.isArray(appointmentDate)) {
		setAppointmentDate(new Date());
	}
	return (
		<div
			className="col selected"
			style={{
				justifyContent: 'space-around',
				alignItems: 'center',
				width: '100%',
				paddingBottom: 20,
			}}
		>
			<div className="row" style={{ width: '95%' }}>
				<img
					src={provider.photo_url_absolute}
					alt="Provider Profile"
					style={{ width: 60, height: 60 }}
				/>
				<div className="col" style={{ width: '75%', marginLeft: 15 }}>
					<h1 style={{ color: '#fff' }}>{provider.fullname}</h1>
					<div>{provider.specialty}</div>
				</div>
				<div className="col">
					{provider.next_appt_available_date && (
						<p>
							{ i18n.t('LABEL.components.forms.choose-provider.next-available', 'Next available:') }
							<br />
							{formattedDate}
						</p>
					)}
					{!provider.next_appt_available_date && (<p>{ i18n.t('LABEL.components.forms.choose-provider.not-available', 'No Availability today') }</p>) }
				</div>
				<div className="col20" />
			</div>
			<div className="row selected" style={{ width: '95%', padding: 0 }}>
				<div className="col50" style={{ width: '50%', paddingRight: 10, flex: 1 }}>
					<span>{providerDetails.about_me}</span>
					<div className="col" style={{ textAlign: 'left', marginTop: 15 }}>
						<h2 style={{ textAlign: 'left', marginBottom: 10 }}>
							{ i18n.t('LABEL.components.forms.choose-provider.qualifications', 'Qualifications') }
						</h2>

						<h3>
							{ i18n.t('LABEL.components.forms.choose-provider.licensed-states', 'Licensed States') }
						</h3>
						{providerDetails.licenses.map((license) => {
							return <span style={{ marginLeft: 10 }}>{license.state}</span>;
						})}

						{providerDetails.publications && (
							<div>
								<h3>
									{ i18n.t('LABEL.components.forms.choose-provider.publications', 'Publications') }
								</h3>
								{providerDetails.publications.map((pub) => {
									return <span>{pub.name}</span>;
								})}
							</div>
						)}
					</div>
				</div>
				<div className="col50" style={{ width: '50%', marginLeft: 10, flex: 1, alignSelf: 'flex-start' }}>
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: 15,
						}}
					>
						<p>{ i18n.t('LABEL.components.forms.choose-provider.desired-day', 'Desired Day For Appointment:') }</p>
						<div className="choose-provider__datepicker-wrapper">
							<DatePicker
								minDate={new Date()}
								selected={appointmentDate}
								onChange={(value) => {
									setAppointmentDate(value);
									getProviderDetails(value);
								}}
								withPortal={isMobile}
								popperPlacement="bottom-end"
							/>
						</div>
					</div>

					{/* {availabilityDetails.available_hours.length > 0 && (
						<div
							style={{
								display: 'flex',
								flex: 1,
								flexWrap: 'wrap',
								flexDirection: 'column',
								alignItems: 'flex-start',
								justifyContent: 'flex-start',
							}}
						>
							<h1 style={{ fontSize: 25, color: '#fff' }}>
								Available Times For 
								{' '}
								{moment(appointmentDate).format('MM/DD/YY')}
:
								{' '}
							</h1>
							<div
								style={{
									display: 'flex',
									flex: 1,
									flexDirection: 'row',
									flexWrap: 'wrap',
									alignItems: 'flex-start',
									paddingRight: 20,
								}}
							>
								{availabilityDetails.available_hours.map((hour, index) => {
									return (
										<div
											style={{
												display: 'inline-block',
												marginRight: 5,
												color: '#fff',
												width: 56,
												textAlign: 'left',
											}}
										>
											{`${moment(hour.timeslot).format('h:mm A')}${
												index < availabilityDetails.available_hours.length - 1 ? ', ' : ''
											}`}
										</div>
									);
								})}
							</div>
						</div>
					)} */}
					{availabilityDetails.available_hours.length > 0 && (
						<button type="submit" onClick={setProvider}>
							{ i18n.t('LABEL.components.forms.choose-provider.request-btn', 'Request appointment') }
						</button>
					)}
					{availabilityDetails.available_hours.length < 1 && (
						<p style={{textAlign: 'left'}}>
							{ i18n.t('LABEL.components.forms.choose-provider.date-no-available', 'Sorry there are no available appointments for {{date}}',
									{ date: moment(appointmentDate).format('MM/DD/YY'), interpolation: { escapeValue: false } }) }
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

const ProviderFilterBar = (providers) => {
	const specialtyFilter = [];
	const genderFilter = ['Female', 'Male'];
	providers.forEach((provider) => {
		if (!specialtyFilter.includes(provider.specialty))
			specialtyFilter.push(provider.specialty);
	});
	specialtyFilter.sort();

	return (
		<p>
			<select name="specialty">
				<option value="">{ i18n.t('LABEL.components.forms.choose-provider.specialty', 'Specialty') }</option>
				{specialtyFilter.map((specialty) => (
					<option value={specialty}>{specialty}</option>
				))}
			</select>
			<select name="gender">
				<option value="">{ i18n.t('LABEL.components.forms.choose-provider.gender', 'Gender') }</option>
				{genderFilter.map((gender) => (
					<option value={gender}>{gender}</option>
				))}
			</select>
		</p>
	);
};

const ChooseProvider = (props) => {
	const providers = useSelector((state) => state.telehealth.providers);
	const dispatch = useDispatch();
	const { history } = props;

	const getNextAvailable = () => {
		dispatch(setSelectedProvider(null));
		history.push('/application/schedule-call');
	};

	const selectedProviderTypeId = useSelector((state) =>
		_.get(state, 'telehealth.selected_provider_type.id', 3)
	);
	return (
		<>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.choose-provider.header', 'Please select a provider from the list below Please') }</h1>
			</div>
			{(selectedProviderTypeId === 3 || selectedProviderTypeId === 2) && (
				<button type="submit" onClick={getNextAvailable} style={{ marginTop: 25 }}>
					{ i18n.t('LABEL.components.forms.choose-provider.next-doctor-btn', 'Request the next available doctor') }
				</button>
			)}
			{/* <div>{ProviderFilterBar(providers)}</div> */}
			<div className="app-content-block-body">
				<div className="rows">
					{providers.map((provider) => ProviderListItem(provider, history))}
				</div>
			</div>
		</>
	);
};

export default withRouter(ChooseProvider);
