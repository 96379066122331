import * as PATIENT from '../constants/patient';

export const setCurrentPatient = patientId => ({
	type: PATIENT.SET_CURRENT_ID,
	patientId,
});

export const setCurrentState = st => ({
	type: PATIENT.SET_CURRENT_STATE,
	currentState: st
});

export const setSelectedState = st => ({
	type: PATIENT.SET_SELECTED_STATE,
	selectedState: st
});

// REMOVE AFTER SHAREABLE INTEGRATION
export const copyPatientToProfile = patient => ({
	type: PATIENT.COPY_PATIENT_TO_PATIENT_PROFILE,
	patient,
});

export const fetchPatientProfilesForFamily = (patients, cb) => ({
	type: PATIENT.FETCH_PROFILES_FOR_FAMILY,
	patients,
	cb,
});

export const fetchPatientProfile = userId => ({
	type: PATIENT.FETCH_PROFILE,
	userId,
});

export const fetchPatientHealthHistory = userId => ({
	type: PATIENT.FETCH_HEALTH_HISTORY,
	userId,
});

export const savePatientPersonalInfo = (data, cb = ()=>{}) => ({
	type: PATIENT.UPDATE_PERSONAL_INFO,
	data,
	cb
});

export const updatePatientHealthHistory = healthHistory => ({
	type: PATIENT.UPDATE_HEALTH_HISTORY,
	healthHistory,
});

export const createHealthCondition = condition => ({
	type: PATIENT.CREATE_HEALTH_CONDITION,
	condition,
});

export const removeHealthCondition = conditionId => ({
	type: PATIENT.REMOVE_HEALTH_CONDITION,
	conditionId,
});

export const createMedication = medication => ({
	type: PATIENT.CREATE_MEDICATION,
	medication,
});

export const removeMedication = medicationId => ({
	type: PATIENT.REMOVE_MEDICATION,
	medicationId,
});

export const createAllergy = allergy => ({
	type: PATIENT.CREATE_ALLERGY,
	allergy,
});

export const removeAllergy = allergyId => ({
	type: PATIENT.REMOVE_ALLERGY,
	allergyId,
});

export const createSurgery = surgery => ({
	type: PATIENT.CREATE_SURGERY,
	surgery,
});

export const removeSurgery = surgeryId => ({
	type: PATIENT.REMOVE_SURGERY,
	surgeryId,
});

export const getBehavioralHistory = () => ({
	type: PATIENT.GET_BEHAVIORAL_HISTORY,
})

export const updateBehavioralHistory = (behavioralHistory, cb) => ({
	type: PATIENT.UPDATE_BEHAVIORAL_HISTORY,
	behavioralHistory,
	cb,
});
