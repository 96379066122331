import {
	all,
	call,
	put,
	select,
	takeLatest,
	takeEvery,
} from 'redux-saga/effects';

import api from '../../services/api';
import * as TELEHEALTH from '../constants/telehealth';
import { getSelectedPatientId, getToken } from '../reducers/telehealth';

export function* fetchAuthToken(action) {
	const response = yield call(api.fetchAuthToken, action.bearerCookie);

	if (response && response.ok) {
		const data = (response.data && response.data.data) || [];
		yield put({
			type: TELEHEALTH.FETCH_AUTH_TOKEN_SUCCESS,
			users: data,
		});
		if (action.cb) {
			yield call(action.cb, true, data);
		}
	} else {
		yield put({
			type: TELEHEALTH.FETCH_AUTH_TOKEN_ERROR,
			error: response.error,
		});
		if (action.cb) {
			yield call(action.cb, false);
		}
		yield call(api.postError, response.data.errors);
	}
}

export function* fetchLuckyOrange(action) {
	const response = yield call(api.fetchLuckyOrange, action.bearerCookie);

	if (response && response.ok) {
		const data = (response.data && response.data.data) || {};
		yield put({
			type: TELEHEALTH.FETCH_LUCKY_ORANGE_SUCCESS,
			settings: data,
		});
		if (action.cb) {
			yield call(action.cb, true, data);
		}
	} else {
		yield put({
			type: TELEHEALTH.FETCH_LUCKY_ORANGE_ERROR,
			error: response.error,
		});
		if (action.cb) {
			yield call(action.cb, false);
		}
		yield call(api.postError, response.data.errors);
	}
}

export function* fetchProviders(action) {
	const token = yield select(getToken);
	const patientId = yield select(getSelectedPatientId);
	const response = yield call(
		api.fetchProviders,
		token,
		patientId,
		action.patientAddressState,
		action.providerTypeId,
	);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_PROVIDERS_SUCCESS,
			providers: data.providers,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_PROVIDERS_ERROR,
			error: response.error,
		});
	}
}

export function* fetchChiefComplaintSearchResults({ query }) {
	const token = yield select(getToken);
	const response = yield call(
		api.fetchChiefComplaintsSearchResults,
		token,
		query
	);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS_SUCCESS,
			results: data.chief_complaints,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS_ERROR,
			error: response.error,
		});
	}
}

export function* fetchAppointmentCost(action) {
	const token = yield select(getToken);
	const response = yield call(
		api.fetchAppointmentCost,
		token,
		action.patientId,
		action.providerTypeId,
		action.stateAbbrev
	);

	if (action.cb) {
		action.cb(response.ok);
	}

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_PROVIDER_TYPES_PRICE_SUCCESS,
			results: data,
			providerTypeId: action.providerTypeId,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_PROVIDER_TYPES_PRICE_FAILURE,
			error: response.error,
		});
	}
}

export function* fetchPatientBillingInfo({ patientId }) {
	const token = yield select(getToken);
	const response = yield call(api.fetchDefaultCCardForPatient, token, patientId);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_PATIENT_BILLING_INFO_SUCCESS,
			results: data.credit_card,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_PATIENT_BILLING_INFO_ERROR,
			error: response.error,
		});
	}
}

export function* updatePatientBillingInfo({ patientId, body }) {
	const token = yield select(getToken);
	const response = yield call(api.upsertPatientCCard, token, patientId, body);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_PATIENT_BILLING_INFO_SUCCESS,
			results: data.credit_card,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_PATIENT_BILLING_INFO_ERROR,
			error: response.error,
		});
	}
}

export function* fetchSpecificProvider(action) {
	const token = yield select(getToken);
	const response = yield call(api.fetchProviderById, token, action.providerId, action.availabilityDate);
	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_SPECIFIC_PROVIDER_SUCCESS,
			availabilityDetails: data.availability_details,
			providerDetails: data.provider_details,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_SPECIFIC_PROVIDER_ERROR,
			error: response.error,
		});
	}
}

export function* fetchPharmacies({ patientId, query }) {
	const token = yield select(getToken);
	const response = yield call(api.fetchPharmacies, token, patientId, query);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_PHARMACY_SUCCESS,
			results: data,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_PHARMACY_ERROR,
			error: response.error,
		});
	}
}

export function* submitMdliveAppointment({
	patientId,
	mdliveRequestObject,
	cb = () => {},
}) {
	const token = yield select(getToken);
	const response = yield call(
		api.requestMdliveAppointment,
		token,
		patientId,
		mdliveRequestObject
	);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.SUBMIT_MDLIVE_APPOINTMENT_SUCCESS,
			mdliveResponse: data,
		});
	} else {
		yield call(api.postError, {call: 'create appointment', patient: patientId, request: mdliveRequestObject, error: response.data.errors});
		yield put({
			type: TELEHEALTH.SUBMIT_MDLIVE_APPOINTMENT_FAILURE,
			error: response.error,
		});
	}
	if (cb) {
		cb(response);
	}
}

export function* fetchUpcomingMdliveAppointments({ patientId }) {
	const token = yield select(getToken);
	const response = yield call(api.fetchUpcomingMdliveAppointments, token, patientId);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_SUCCESS,
			mdliveAppointment: data,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_FAILURE,
			error: response.error,
		});
	}
}

export function* fetchMdliveAppointmentInfo({ appointmentId }) {
	const token = yield select(getToken);
	const response = yield call(
		api.fetchMdliveAppointmentInfo,
		token,
		appointmentId
	);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_INFO_SUCCESS,
			mdliveAppointmentInfo: data,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_INFO_FAILURE,
			error: response.error,
		});
	}
}

export function* cancelMdliveAppointment({ patientId, appointmentId }) {
	const token = yield select(getToken);
	const response = yield call(
		api.cancelMdliveAppointment,
		token,
		patientId,
		appointmentId
	);

	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.CANCEL_MDLIVE_APPOINTMENT_SUCCESS,
			mdliveAppointmentInfo: data,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.CANCEL_MDLIVE_APPOINTMENT_FAILURE,
			error: response.error,
		});
	}
}

export function* uploadPatientImageToMDLIVE({ imageToUpload, cb }) {
	const token = yield select(getToken);
	const patientId = yield select(getSelectedPatientId);
	const response = yield call(
		api.uploadPatientImage,
		token,
		patientId,
		imageToUpload
	);
	if(cb){
		cb(response.ok);
	}
	if (response.ok) {
		const { data } = response;
		yield put({
			type: TELEHEALTH.IMAGE_UPLOAD_SUCCESS,
			imageId: data.doc.id,
		});
	} else {
		yield call(api.postError, response.data.errors);
		yield put({
			type: TELEHEALTH.IMAGE_UPLOAD_FAILURE,
			error: response.error,
		});
	}
}

export default function* root() {
	yield all([
		takeEvery(TELEHEALTH.FETCH_APPOINTMENT_COST, fetchAppointmentCost),
		takeLatest(TELEHEALTH.FETCH_AUTH_TOKEN, fetchAuthToken),
		takeLatest(TELEHEALTH.FETCH_LUCKY_ORANGE, fetchLuckyOrange),
		takeLatest(TELEHEALTH.FETCH_PROVIDERS, fetchProviders),
		takeLatest(
			TELEHEALTH.FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS,
			fetchChiefComplaintSearchResults
		),
		takeLatest(TELEHEALTH.FETCH_PATIENT_BILLING_INFO, fetchPatientBillingInfo),
		takeLatest(TELEHEALTH.UPDATE_PATIENT_BILLING_INFO, updatePatientBillingInfo),
		takeLatest(TELEHEALTH.FETCH_PHARMACY, fetchPharmacies),
		takeLatest(TELEHEALTH.FETCH_SPECIFIC_PROVIDER, fetchSpecificProvider),
		takeLatest(TELEHEALTH.SUBMIT_MDLIVE_APPOINTMENT, submitMdliveAppointment),
		takeLatest(TELEHEALTH.FETCH_MDLIVE_APPOINTMENT, fetchUpcomingMdliveAppointments),
		takeLatest(
			TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_INFORMATION,
			fetchMdliveAppointmentInfo
		),
		takeLatest(TELEHEALTH.CANCEL_MDLIVE_APPOINTMENT, cancelMdliveAppointment),
		takeLatest(TELEHEALTH.SUBMIT_IMAGE_UPLOAD, uploadPatientImageToMDLIVE),
	]);
}
