import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

const PageBackground = ({ name, cssClass, altText }) => {
	let highResolutionsBg;
	let cssClasses = PageBackground.defaultProps.cssClass;
	const bg = `/resources/mdlive/images/${name}/${name}-bg.png`;
	const bg2x = `/resources/mdlive/images/${name}/${name}-bg@2x.png`;
	const bg3x = `/resources/mdlive/images/${name}/${name}-bg@3x.png`;

	if (bg2x) {
		highResolutionsBg = `${bg2x} 2x`

		if (bg3x) {
			highResolutionsBg += `, ${bg3x} 3x`;
		}
	}

	let defaulResolutionsBg;
	const defaultBG = process.env.PUBLIC_URL + `/assets/img/${name}/${name}-bg.png`;
	const defaultBG2x = process.env.PUBLIC_URL + `/assets/img/${name}/${name}-bg@2x.png`;
	const defaultBG3x = process.env.PUBLIC_URL + `/assets/img/${name}/${name}-bg@3x.png`;

	if (defaultBG2x) {
		defaulResolutionsBg = `${defaultBG2x} 2x`

		if (defaultBG3x) {
			defaulResolutionsBg += `, ${defaultBG3x} 3x`;
		}
	}

	if (cssClass) {
		cssClasses += ` ${cssClass}`;
	}

	return(
		!isMobile ? (
			<img
				src={bg}
				srcSet={highResolutionsBg}
				className={cssClasses}
				alt={altText}
				onError={ ({ currentTarget }) => {
					currentTarget.onError = null;
					currentTarget.src = defaultBG;
					currentTarget.srcset = defaulResolutionsBg;
				}}
			/>
		) : null
	);
}

PageBackground.defaultProps = {
	cssClass: 'app-content-wrapper-bg',
	altText: ''
};

PageBackground.propTypes = {
	name: PropTypes.string.isRequired,
	cssClass: PropTypes.string,
	altText: PropTypes.string
};

export default PageBackground;
