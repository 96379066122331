import apisauce from 'apisauce';
import moment from 'moment';

const HEADERS = {
	'Content-Type': 'application/json',
	'x-application-signature': process.env.REACT_APP_APPLICATION_KEY,
};

const create = (
	baseURL = `${process.env.REACT_APP_MDLIVE_BASE_URL}/`,
	headers = HEADERS
) => {
	const api = apisauce.create({
		baseURL,
		headers,
		withCredentials: !(process.env.NODE_ENV === 'development'),
		timeout: 30000,
	});

	const reverseGeoCode = position => {
		api.setBaseURL('https://api.bigdatacloud.net/');
		return api.get(`data/reverse-geocode-client`, {
			latitude: position.coords.latitude,
			longitude: position.coords.longitude,
			localityLanguage: 'en',
		});
	};

	// ------------------------------------  Telehealth ---------------------------------- //
	const fetchAuthToken = bearerCookie => {
		api.setBaseURL(`${process.env.REACT_APP_API_BASE_URL}/`);
		return api.post(`api/telehealth/token`, {
			bearerCookie,
		});
	};

	const fetchLuckyOrange = bearerCookie => {
		api.setBaseURL(`${process.env.REACT_APP_API_BASE_URL}/`);
		return api.post(`api/telehealth/lucky-orange`, {
			bearerCookie,
		});
	};
  
	const postError = (errorMessage) => {
		api.setBaseURL(`${process.env.REACT_APP_API_BASE_URL}/`);
		return api.post(`api/telehealth/error`, {
			errorMessage,
		});
	}

	const fetchMDLivePatientProfile = (token, patientId) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v2/patients/${patientId}/`, {});
	};

	const fetchMDLivePatientHealthHistory = (token, patientId) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v2/patients/${patientId}/health_conditions`);
	};

	const updatePatientHealthHistory = (token, patientId, healthHistory) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v2/patients/${patientId}/health_history`, healthHistory);
	};

	const updatePatient = (token, patientId, body) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.patch(`api/v2/patients/${patientId}`, body);
	};

	const fetchProviders = (token, patientId, patientAddressState, providerTypeId) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v2/patients/${patientId}/providers/search`, {
			search: {
				state_abbrev: patientAddressState,
				provider_type_id: providerTypeId,
			},
		});
	};

	const createMedicalCondition = (token, patientId, medicalCondition) => {
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(
			`api/v1/patients/${patientId}/medical_conditions`,
			medicalCondition
		);
	};

	const removeMedicalCondition = (token, patientId, conditionId) => {
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.delete(
			`api/v1/patients/${patientId}/medical_conditions/${conditionId}`,
			null
		);
	};

	const createMedication = (token, patientId, medication) => {
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v2/patients/${patientId}/medications`, medication);
	};

	const removeMedication = (token, patientId, medicationId) => {
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.delete(
			`api/v1/patients/${patientId}/medications/${medicationId}`,
			null
		);
	};

	const createAllergy = (token, patientId, allergy) => {
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v2/patients/${patientId}/allergies`, allergy);
	};

	const removeAllergy = (token, patientId, allergyId) => {
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.delete(
			`api/v1/patients/${patientId}/medications/${allergyId}`,
			null
		);
	};

	const createSurgery = (token, patientId, surgery) => {
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v2/patients/${patientId}/surgeries`, surgery);
	};

	const removeSurgery = (token, patientId, surgeryId) => {
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.delete(
			`api/v1/patients/${patientId}/surgeries/${surgeryId}`,
			null
		);
	};

	const fetchChiefComplaintsSearchResults = (token, query) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v1/patients/chief_complaints?search[term]=${query}`);
	};

	const fetchAppointmentCost = (
		token,
		patientId,
		providerTypeId,
		stateAbbrev
	) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(
			`api/v2/payments/costs/check?patient_id=${patientId}&provider_type_id=${providerTypeId}&state_abbrev=${stateAbbrev}&authorize_insurance=false`
		);
	};

	const fetchDefaultCCardForPatient = (token, patientId) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v1/patients/${patientId}/credit_card`);
	};

	const upsertPatientCCard = (token, patientId, body) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v2/patients/${patientId}/credit_card`, body);
	};

	const fetchProviderById = (token, providerId, availabiltyDate) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v2/providers/${providerId}/profile?specific_date=${moment(availabiltyDate).format("YYYY-MM-DD")}`);
		// return api.get(`api/v2/providers/${providerId}/profile`);
	};

	const fetchPharmacies = (token, patientId, query) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v1/patients/${patientId}/pharmacies/search`, query);
	};

	const fetchConditionsSuggestions = (token, term) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v1/clinical_concepts`, { 'search[term]': term });
	};

	const fetchMedicationSuggestions = (token, term) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v2/medications/search`, { search: { term } });
	};

	const fetchAllergySuggestions = (token, term) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v1/allergies/search`, { search: { term } });
	};

	const fetchSurgeryList = token => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v2/surgery_names`);
	};

	const requestMdliveAppointment = (token, patientId, mdliveRequestObject) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(
			`api/v2/patients/${patientId}/appointments`,
			mdliveRequestObject
		);
	};

	const fetchUpcomingMdliveAppointments= (token, patientId) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v2/patients/${patientId}/appointments/upcoming`);
	};

	const fetchMdliveAppointmentInfo = (token, appointmentId) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v1/appointments/${appointmentId}/connection_params`);
	};

	const cancelMdliveAppointment = (token, patientId, appointmentId) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.put(
			`api/v1/patients/${patientId}/appointments/${appointmentId}/cancel`
		);
	};
  
	const getBehavioralHistory = (token, patientId) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.get(`api/v1/patients/${patientId}/behavioral_histories`);
	}

	const updateBehavioralHistory = (token, patientId, behavioralHistory) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v1/patients/${patientId}/behavioral_histories`, {
			behavioral_history: behavioralHistory,
		});
	};

	const uploadPatientImage = (token, patientId, image) => {
		api.setBaseURL(`${process.env.REACT_APP_MDLIVE_BASE_URL}/`);
		token && api.setHeader('Authorization', `Bearer ${token}`);
		return api.post(`api/v2/patients/${patientId}/documents`, {
			doc: {
				document_name: image.imageName,
				data: image.base64Data,
				mime_type: image.imageType,
				record_type: 'Patient Record',
			},
		});
	};

	return {
		fetchMDLivePatientProfile,
		fetchMDLivePatientHealthHistory,
		fetchPharmacies,
		fetchChiefComplaintsSearchResults,
		fetchAppointmentCost,
		fetchDefaultCCardForPatient,
		upsertPatientCCard,
		fetchProviderById,
		updatePatient,
		updatePatientHealthHistory,
		fetchAuthToken,
		fetchLuckyOrange,
		fetchProviders,
		createMedicalCondition,
		removeMedicalCondition,
		createMedication,
		removeMedication,
		createAllergy,
		removeAllergy,
		createSurgery,
		removeSurgery,
		fetchConditionsSuggestions,
		fetchMedicationSuggestions,
		fetchAllergySuggestions,
		fetchSurgeryList,
		requestMdliveAppointment,
		fetchUpcomingMdliveAppointments,
		fetchMdliveAppointmentInfo,
		cancelMdliveAppointment,
		getBehavioralHistory,
		updateBehavioralHistory,
		reverseGeoCode,
		uploadPatientImage,
		postError
	};
};

const api = create();

export default api;
