import storage from "redux-persist/lib/storage";
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from "redux-persist-seamless-immutable";

const reduxPersistConfiguration = {
	key: "root",
	version: 0,
	storage,
	blacklist: [],
	stateReconciler: seamlessImmutableReconciler,
	transforms: [seamlessImmutableTransformCreator({})],
};

export default reduxPersistConfiguration;
