import * as TELEHEALTH from '../constants/telehealth';

export const fetchToken = (bearerCookie, cb) => {
	return {
		type: TELEHEALTH.FETCH_AUTH_TOKEN,
		bearerCookie,
		cb,
	};
};

export const fetchLuckyOrangeCode = (bearerCookie, cb) => {
	return {
		type: TELEHEALTH.FETCH_LUCKY_ORANGE,
		bearerCookie,
		cb,
	};
};

export const setToken = token => ({
	type: TELEHEALTH.SET_AUTH_TOKEN,
	token,
});

export const setUserId = userId => ({
	type: TELEHEALTH.SET_USER_ID,
	id: userId,
});

export const setSelectedProviderType = provider => {
	return {
		type: TELEHEALTH.SET_SELECTED_PROVIDER_TYPE,
		provider,
	};
};

export const fetchProviders = (patientAddressState, providerTypeId) => ({
	type: TELEHEALTH.FETCH_PROVIDERS,
	patientAddressState,
	providerTypeId,
});

export const setSelectedProvider = provider => ({
	type: TELEHEALTH.SET_SELECTED_PROVIDER,
	provider,
});

export const fetchChiefComplaintSearchResults = query => ({
	type: TELEHEALTH.FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS,
	query,
});

export const setChiefComplaint = complaint => ({
	type: TELEHEALTH.SET_SELECTED_CHIEF_COMPLAINT,
	complaint,
});

export const setChiefComplaintComment = comment => ({
	type: TELEHEALTH.SET_SELECTED_CHIEF_COMPLAINT_COMMENT,
	comment,
});

export const fetchAppointmentCost = (
	patientId,
	providerTypeId,
	stateAbbrev,
	cb = () => {}
) => ({
	type: TELEHEALTH.FETCH_APPOINTMENT_COST,
	patientId,
	providerTypeId,
	stateAbbrev,
	cb,
});

export const fetchPatientBillingInfo = patientId => ({
	type: TELEHEALTH.FETCH_PATIENT_BILLING_INFO,
	patientId,
});

export const updatePatientBillingInfo = (patientId, body) => ({
	type: TELEHEALTH.UPDATE_PATIENT_BILLING_INFO,
	patientId,
	body,
});

export const fetchSpecificProvider = (providerId, availabilityDate) => ({
	type: TELEHEALTH.FETCH_SPECIFIC_PROVIDER,
	providerId,
	availabilityDate
});

export const fetchPharmacySearch = (patientId, query) => ({
	type: TELEHEALTH.FETCH_PHARMACY,
	patientId,
	query,
});

export const submitMdliveAppointment = (
	patientId,
	mdliveRequestObject,
	cb
) => ({
	type: TELEHEALTH.SUBMIT_MDLIVE_APPOINTMENT,
	patientId,
	mdliveRequestObject,
	cb,
});

export const fetchUpcomingMdliveAppointments= patientId => ({
	type: TELEHEALTH.FETCH_MDLIVE_APPOINTMENT,
	patientId,
});

export const fetchMdliveAppointmentInformation = appointmentId => ({
	type: TELEHEALTH.FETCH_MDLIVE_APPOINTMENT_INFORMATION,
	appointmentId,
});

export const cancelMdliveAppointment = (patientId, appointmentId) => ({
	type: TELEHEALTH.CANCEL_MDLIVE_APPOINTMENT,
	patientId,
	appointmentId,
});

export const uploadImage = (imageToUpload, cb) => ({
	type: TELEHEALTH.SUBMIT_IMAGE_UPLOAD,
	imageToUpload,
	cb
});

export const resetAllState = () => ({
	type: 'RESET',
});
