export const SET_CURRENT_ID = 'SET_CURRENT_ID';
export const SET_CURRENT_STATE = 'SET_CURRENT_STATE';
export const SET_SELECTED_STATE = 'SET_SELECTED_STATE';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILES_FOR_FAMILY = 'FETCH_PROFILES_FOR_FAMILY';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';
export const ADD_FAMILY_PROFILE = 'ADD_FAMILY_PROFILE';
export const RESET_FAMILY_PROFILE = 'RESET_FAMILY_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_ERROR = 'SET_PROFILE_ERROR';
export const FETCH_HEALTH_HISTORY = 'FETCH_HEALTH_HISTORY';
export const SET_HEALTH_HISTORY = 'SET_HEALTH_HISTORY';
export const UPDATE_HEALTH_HISTORY = 'UPDATE_HEALTH_HISTORY';
export const SET_HEALTH_CONDITIONS = 'SET_HEALTH_CONDITIONS';
export const ADD_ALLERGY = 'ADD_ALLERGY';
export const UPDATE_ALLERGY = 'UPDATE_ALLERGY';
export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO';
export const CREATE_HEALTH_CONDITION = 'CREATE_HEALTH_CONDITION';
export const REMOVE_HEALTH_CONDITION = 'REMOVE_HEALTH_CONDITION';
export const CREATE_MEDICATION = 'CREATE_MEDICATION';
export const REMOVE_MEDICATION = 'REMOVE_MEDICATION';
export const CREATE_ALLERGY = 'CREATE_ALLERGY';
export const REMOVE_ALLERGY = 'REMOVE_ALLERGY';
export const CREATE_SURGERY = 'CREATE_SURGERY';
export const REMOVE_SURGERY = 'REMOVE_SURGERY';
export const UPDATE_BEHAVIORAL_HISTORY = 'UPDATE_BEHAVIORAL_HISTORY';
export const GET_BEHAVIORAL_HISTORY = 'GET_BEHAVIORAL_HISTORY';
export const SET_BEHAVIORAL_HEALTH_HISTORY = 'SET_BEHAVIORAL_HEALTH_HISTORY';

// REMOVE AFTER SHAREABLE INTEGRATION
export const COPY_PATIENT_TO_PATIENT_PROFILE =
	'COPY_PATIENT_TO_PATIENT_PROFILE';
