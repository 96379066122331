import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import createStore from './redux/createStore';
import AppRoutes from './navigation/AppRoutes';
import reduxService from './services/reduxService';
import './i18n';

class App extends PureComponent {
	constructor(props) {
		super(props);
		const { store, persistor } = createStore();
		this.store = store;
		this.persistor = persistor;
		persistor.purge();
		reduxService.setTopLevelStore(store);
	}

	render() {
		return (
			<Provider store={this.store}>
				<PersistGate loading={null} persistor={this.persistor}>
					<AppRoutes />
				</PersistGate>
			</Provider>
		);
	}
}

export default App;
