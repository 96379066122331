import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import ChooseProviderForm from '../../components/forms/ChooseProvider';
import { PageBackground } from '../../components';
import i18n from 'i18next';

const ChooseProvider = props => {
	const alert = useAlert();

	const patient = useSelector(state => state.patient.patient_profile);
	const behavioralHistory = useSelector(state => state.patient.behavioral_history);
	const pharmacy =  useSelector(state => _.get(state.patient, 'patient_profile.pharmacy.id', null));
	const visitReason = useSelector(state => state.telehealth.chief_complaint);
	const visitReasonComent = useSelector(state => state.telehealth.chief_complaint_comment);
	const selectedProviderType = useSelector(state => state.telehealth.selected_provider_type);
	const isMedicalVisit = selectedProviderType && selectedProviderType.name === "Medical Visit";
	const { history } = props;

	alert.removeAll();
	if (!patient) {
		alert.show(i18n.t('LABEL.scenes.choose-provider.patient-error-msg', 'Oh no!, it looks like there was a problem'));
		console.log('no patient so we are heading back to select one');
		history.push('/select_patient');
	} else if (!visitReason || !visitReasonComent) {
		alert.show(i18n.t('LABEL.scenes.choose-provider.visit-reason-error-msg', 'Oh no!, it looks like you forgot to select a reason for your visit!'));
		console.log('no reason for visit, must select one first');
		history.push('/application/reason-for-visiting');
	} else if (!isMedicalVisit && !behavioralHistory) {
		alert.show(i18n.t('LABEL.scenes.choose-provider.behavioral-health-error-msg', 'Oh no!, it looks like you forgot to fill out your behavioral health information!'));
		console.log('no behavioral health information, must fill out form');
		history.push('/application/health-profile/behavioral');
	} else if (!pharmacy){
		alert.show(i18n.t('LABEL.scenes.choose-provider.pharmacy-error-msg', 'Oh no!, it looks like you don\'t have a pharmacy selected!'));
		console.log('we do not have a pharmacy.  Heading back to pick one', pharmacy);
		history.push('/application/pharmacy-search');
	}
  
  
	return (
		<div className="app-content-wrapper">
			<PageBackground
				name="wizard-choose-provider"
			/>
			<div className="app-content" style={{ maxWidth: 1000 }}>
				<div className="app-content-block">
					<ChooseProviderForm />
				</div>
			</div>
		</div>
	);
}

export default ChooseProvider;
