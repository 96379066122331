import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import valid from 'card-validator';
import { updatePatientBillingInfo } from '../../redux/actions/telehealth';
import i18n from 'i18next';

const BillingSchema = () => Yup.object().shape({
	billingName: Yup.string()
		.min(2, i18n.t('LABEL.components.forms.billing-data.name-min-error', 'Name on Card is too short'))
		.max(50, i18n.t('LABEL.components.forms.billing-data.name-max-error', 'Name on Card is too long'))
		.required(i18n.t('LABEL.components.forms.billing-data.name-required-error', 'Name on Card is required')),
	billingAddress: Yup.string()
		.min(2, i18n.t('LABEL.components.forms.billing-data.address-min-error', 'Billing Address is too short'))
		.max(50, i18n.t('LABEL.components.forms.billing-data.address-max-error', 'Billing Address is too long'))
		.required(i18n.t('LABEL.components.forms.billing-data.address-required-error', 'Billing Address is required')),
	billingCity: Yup.string()
		.min(2, i18n.t('LABEL.components.forms.billing-data.city-min-error', 'Billing City is too short'))
		.max(50, i18n.t('LABEL.components.forms.billing-data.city-max-error', 'Billing City is too long'))
		.required(i18n.t('LABEL.components.forms.billing-data.city-required-error', 'Billing City is required')),
	billingState: Yup.string()
		.length(2,  i18n.t('LABEL.components.forms.billing-data.state-length-error', 'Billing State is invalid'))
		.required(i18n.t('LABEL.components.forms.billing-data.state-required-error', 'Billing State is required')),
	billingZipCode: Yup.string()
		.length(5, i18n.t('LABEL.components.forms.billing-data.zipcode-length-error', 'Billing Zip Code is invalid'))
		.required(i18n.t('LABEL.components.forms.billing-data.zipcode-required-error', 'Billing Zip Code is required')),
	billingCardNumber: Yup.string()
		.test(
			'test-number', // this is used internally by yup
			i18n.t('LABEL.components.forms.billing-data.card-number-test-error', 'Credit Card Number is invalid'), // validation message
			value => valid.number(value).isValid
		) // return true false based on validation
		.required(i18n.t('LABEL.components.forms.billing-data.card-number-required-error', 'Credit Card Number is required')),
	billingCardExpMonth: Yup.string()
		.test(
			'test-number',
			i18n.t('LABEL.components.forms.billing-data.exp-month-test-error', 'Credit Card Experation Month is invalid'),
			value => valid.expirationMonth(value).isValid
		)
		.required(i18n.t('LABEL.components.forms.billing-data.exp-month-required-error', 'Reguired')),
	billingCardExpYear: Yup.string()
		.test(
			'test-number',
			i18n.t('LABEL.components.forms.billing-data.exp-year-test-error', 'Credit Card Experation Year is invalid'),
			value => valid.expirationYear(value).isValid
		)
		.required(i18n.t('LABEL.components.forms.billing-data.exp-year-required-error', 'Required')),
	billingCardCVV: Yup.string()
		.test(
			'test-number',
			i18n.t('LABEL.components.forms.billing-data.card-cvv-test-error', 'Credit Card CVV is invalid'),
			value => valid.cvv(value).isValid
		)
		.required(i18n.t('LABEL.components.forms.billing-data.card-cvv-required-error', 'Required')),
});

const BillingData = props => {
	const patient = useSelector(state => state.patient.patient_profile);
	const billingInfo = useSelector(state => state.telehealth.billing_info);
	const dispatch = useDispatch();

	return (
		<div>
			<div className="app-content-block-header">
			  <h1>{ i18n.t('LABEL.components.forms.billing-data.form-header', 'Please enter your billing information') }</h1>
			</div>
			<div className="app-content-block-body">
				<Formik
					initialValues={{
						billingName: _.get(billingInfo, 'billing_data.name', ''),
						billingAddress: _.get(billingInfo, 'billing_data.address1', ''),
						billingCity: _.get(billingInfo, 'billing_data.city', ''),
						billingState: _.get(billingInfo, 'billing_data.state', ''),
						billingZipCode: _.get(billingInfo, 'billing_data.zip', ''),
						billingCardNumber: '',
						billingCardCVV: '',
						billingCardExpMonth: '',
						billingCardExpYear: '',
					}}
					validationSchema={BillingSchema()}
					onSubmit={(values) => {
						const billingBody = {
							credit_card: {
								billing_data: {
									address1: values.billingAddress,
									address2: '',
									city: values.billingCity,
									state_abbrev: values.billingState,
									zip: values.billingZipCode,
									name: values.billingName,
								},
								card_data: {
									card_number: values.billingCardNumber,
									cvv: values.billingCardCVV,
									expiration_month: values.billingCardExpMonth,
									expiration_year: values.billingCardExpYear,
								},
							},
						};

						dispatch(updatePatientBillingInfo(patient.id, billingBody));
						if (props.onSubmit) {
							props.onSubmit(values);
						}
					}}
				>
					{({ errors, touched }) => (
						<Form>
							<div className="input-container">
								{errors.billingName && touched.billingName ? (
									<label htmlFor="billingName" className="error">{errors.billingName}</label>
								) : (
									<label htmlFor="billingName">
										{ i18n.t('LABEL.components.forms.billing-data.name-label', 'Name on Card') }
									</label>
								)}
								<Field name="billingName" />
							</div>
							<div className="input-container">
								{errors.billingAddress && touched.billingAddress ? (
									<label htmlFor="billingAddress" className="error">{errors.billingAddress}</label>
								) : (
									<label htmlFor="billingAddress">
										{ i18n.t('LABEL.components.forms.billing-data.address-label', 'Billing Address') }
									</label>
								)}
								<Field name="billingAddress" />
							</div>
							<div className="input-container">
								{errors.billingCity && touched.billingCity ? (
									<label htmlFor="billingCity" className="error">{errors.billingCity}</label>
								) : (
									<label htmlFor="billingCity">
										{ i18n.t('LABEL.components.forms.billing-data.city-label', 'Billing City') }
									</label>
								)}
								<Field name="billingCity" />
							</div>
							<div className="input-container">
								{errors.billingState && touched.billingState ? (
									<label htmlFor="billingState" className="error">{errors.billingState}</label>
								) : (
									<label htmlFor="billingState">
										{ i18n.t('LABEL.components.forms.billing-data.state-label', 'Billing State') }
									</label>
								)}
								<Field name="billingState" />
							</div>
							<div className="input-container">
								{errors.billingZipCode && touched.billingZipCode ? (
									<label htmlFor="billingZipCode" className="error">{errors.billingZipCode}</label>
								) : (
									<label htmlFor="billingZipCode">
										{ i18n.t('LABEL.components.forms.billing-data.zipcode-label', 'Billing Zip Code') }
									</label>
								)}
								<Field name="billingZipCode" />
							</div>
							<div className="input-container">
								{errors.billingCardNumber && touched.billingCardNumber ? (
									<label htmlFor="billingCardNumber" className="error">{errors.billingCardNumber}</label>
								) : (
									<label htmlFor="billingCardNumber">
										{ i18n.t('LABEL.components.forms.billing-data.card-number-label', 'Credit Card Number') }
									</label>
								)}
								<Field name="billingCardNumber" />
							</div>
							<div className="input-container">
								{errors.billingCardCVV && touched.billingCardCVV ? (
									<label htmlFor="billingCardCVV" className="error">{errors.billingCardCVV}</label>
								) : (
									<label htmlFor="billingCardCVV">
										{ i18n.t('LABEL.components.forms.billing-data.card-cvv-label', 'CVV') }
									</label>
								)}
								<Field name="billingCardCVV" />
							</div>
							<div className="input-container">
								{errors.billingCardExpMonth && touched.billingCardExpMonth ? (
									<label htmlFor="billingCardExpMonth" className="error">{errors.billingCardExpMonth}</label>
								) : (
									<label htmlFor="billingCardExpMonth">
										{ i18n.t('LABEL.components.forms.billing-data.exp-month-label', 'Exp Month') }
									</label>
								)}
								<Field name="billingCardExpMonth" />
							</div>
							<div className="input-container">
								{errors.billingCardExpYear && touched.billingCardExpYear ? (
									<label htmlFor="billingCardExpYear" className="error">{errors.billingCardExpYear}</label>
								) : (
									<label htmlFor="billingCardExpYear">
										{ i18n.t('LABEL.components.forms.billing-data.exp-year-label', 'Exp Year') }
									</label>
								)}
								<Field name="billingCardExpYear" />
							</div>
							<div className="button-container-right">
								<button type="submit">{props.submitButtonText}</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			<div />
		</div>
	);
};

BillingData.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default BillingData;
