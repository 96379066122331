import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLoader } from '../../services';
import { fetchPatientBillingInfo } from '../../redux/actions/telehealth';
import BillingDataForm from '../../components/forms/BillingData';
import { PageBackground } from '../../components';
import i18n from 'i18next';

const Payment = props => {
	const [setLoading] = useLoader(false);
	const providerType = useSelector(
		state => state.telehealth.selected_provider_type
	);
	const patient = useSelector(state => state.patient.patient_profile);
	const billingInfo = useSelector(state => state.telehealth.billing_info);
	const { history } = props;
	const dispatch = useDispatch();

	if (!patient) {
		history.push('/select_patient');
	} else if (!providerType) {
		history.push('/select_patient');
	}

	useEffect(() => {
		if (patient) {
			dispatch(fetchPatientBillingInfo(patient.id));
		}
	}, []);

	const navigateToNextPage = () => {
		setLoading(true);
		setTimeout(() => {
			history.push('/application/reason-for-visiting');
		}, 1000);
	};
	return (
		<div className="app">
			<div className="app-header">
				<h1 style={{ textAlign: 'center' }}>{ i18n.t('LABEL.scenes.payment.page-header', 'Billing Information') }</h1>
			</div>
			<div className="app-content-wrapper">
				<PageBackground
					name="wizard-personal-info"
				/>
				<div className="app-content-container">
					<div className="app-content" style={{ maxWidth: 1000, flexDirection: 'column' }}>
						{billingInfo && (
							<div className="app-content-block">
								<div className="app-content-block-header">
									<h1>{ i18n.t('LABEL.scenes.payment.card-header', 'Saved Card') }</h1>
								</div>
								<div className="app-content-block-body">
									<h2>
										{ i18n.t('LABEL.scenes.payment.card-name', 'Name on Card: {{name}}', { name: _.get(billingInfo, 'billing_data.name', 'Unknown') }) }
									</h2>
									<p>
										{ i18n.t('LABEL.scenes.payment.card-last-four', 'Last four: {{number}}', { number: _.get(billingInfo, 'card_data.last_four', '0000') }) }
									</p>
									<p>
										{ i18n.t('LABEL.scenes.payment.card-exp-date', 'Exp Date: {{month}}/{{year}}', {
												month: _.get(billingInfo, 'card_data.expiration_month', '00'),
												year: _.get(billingInfo, 'card_data.expiration_year', '0000')
											}) }
									</p>
									<p>
										{ i18n.t('LABEL.scenes.payment.card-type', 'Card type: {{type}}', { type: _.get(billingInfo, 'card_data.type', 'Unknown') }) }
									</p>
									<p>
										{ i18n.t('LABEL.scenes.payment.card-address', 'Address: {{address}}', { address: _.get(billingInfo, 'billing_data.address1', 'Unknown') }) }
									</p>
									<p>
										{ i18n.t('LABEL.scenes.payment.card-city', 'City: {{city}}', { city: _.get(billingInfo, 'billing_data.city', 'Unknown') }) }
									</p>
									<p>
										{ i18n.t('LABEL.scenes.payment.card-state', 'State: {{state}}', { state: _.get(billingInfo, 'billing_data.state', 'Unknown') }) }
									</p>
									<button onClick={navigateToNextPage}>{ i18n.t('LABEL.scenes.payment.use-card-btn', 'Use this card') }</button>
								</div>
							</div>
						)}
						<div className="app-content-block">
							<BillingDataForm submitButtonText="Next" onSubmit={navigateToNextPage} />
						</div>

					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Payment);
