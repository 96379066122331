import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import {
	createSurgery as createSurgeryAction,
	removeSurgery as removeSurgeryAction,
	updatePatientHealthHistory as updatePatientHealthHistoryAction,
} from '../../redux/actions/patient';
import api from '../../services/api';
import { IconSVG } from '../icon';
import i18n from 'i18next';

const useSurgeryList = () => {
	const [surgeries, setSurgeries] = useState([]);

	const fetchSurgeryList = async (token) => {
		const response = await api.fetchSurgeryList(token);
		if (response.ok) {
			setSurgeries(response.data.surgery_names);
		} else {
			setSurgeries([]);
		}
	}

	return [surgeries, fetchSurgeryList];
}

const Surgeries = props => {
	const [surgeryList, fetchSurgeryList] = useSurgeryList();

	const { 
		healthHistory, 
		createSurgery, 
		removeSurgery, 
		updatePatientHealthHistory,
		submitButtonText,
		token,
		history
	} = props;
	const {
		surgeries_taken: surgeries,
		had_surgeries: hadSurgeries,
	} = healthHistory;

	useEffect(() => {
		if (token) {
			fetchSurgeryList(token);
		}
	}, [token])

	return (
		<div>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.surgeries.header', 'Have you ever had any surgeries or medical procedures?') }</h1>
			</div>
			<div className="app-content-block-body">
				{surgeries.length > 0 && (
					<div className="col">
						<div className="row" style={{justifyContent:'space-around'}}>
							<div className="col50 header">
								{ i18n.t('LABEL.components.forms.surgeries.surgeries', 'Surgeries') }
							</div>
							<div className="col30 header">
								{ i18n.t('LABEL.components.forms.surgeries.year', 'Year') }
							</div>
							<div className="col10" />
						</div>
						{surgeries.map((surgery) => (
							<div key={surgery.id} className="row tableBody">
								<div className="col50">{surgery.name}</div>
								<div className="col30">{surgery.year}</div>
								<div className="col10">
									<button
										type="button"
										onClick={() => removeSurgery(surgery.id)}
									>
										<IconSVG
											name="delete"
											cssClasses="delete"
										/>
									</button>
								</div>
							</div>
						))}
					</div>
				)}
				<Formik
					initialValues={{
						name: "",
						surgery_year: ""
					}}
					onSubmit={(values) => {
						createSurgery({ surgery: values });
					}}
				>
					{({ setFieldValue }) => (
						<Form>
							<div className="input-container" style={{marginTop: 20}}>
								<label htmlFor="name">
									{ i18n.t('LABEL.components.forms.surgeries.surgery-label', 'Surgery') }
								</label>
								<Field
									name="name"
									autoComplete="off"
								/>
								{surgeryList.length > 0 && (
									<div className="suggestions-container">
										{surgeryList.map(surgery => (
											<div
												className="suggestion-item"
												key={surgery.name}
												onMouseDown={() => {
													setFieldValue("name", surgery.name); 
												}}
											>
												<div><strong>{surgery.name}</strong></div>
											</div>
										))}
									</div>
								)}
							</div>
							<div className="input-container">
								<label htmlFor="surgery_year">
									{ i18n.t('LABEL.components.forms.surgeries.year-label', 'Year') }
								</label>
								<Field name="surgery_year" />
							</div>
							<div className="button-container">
								<button type="submit">
									{ i18n.t('LABEL.components.forms.surgeries.add-btn', 'Add Surgery') }
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			<div className="app-content-block-footer">
				<div className="button-container" style={{justifyContent: 'space-between'}}>
					<button
						onClick={() => {
							history.goBack();
						}}
						type="button"
						className="default"
						style={{marginTop: 15}}
					>
						{ i18n.t('LABEL.components.forms.surgeries.back-btn', 'Back') }
					</button>
					<button 
						onClick={() => {
							updatePatientHealthHistory({
								medical_history: {
									surgeries: surgeries.length > 0,
								},
							});
							props.onSubmit();
						}}
						type="submit"
					>
						{ surgeries.length > 0 ? submitButtonText : i18n.t('LABEL.components.forms.surgeries.not-have-surgery-btn', 'No, I have not had any surgeries.') }
					</button>
				</div>
			</div>
		</div>
	);
};

Surgeries.propTypes = {
	submitButtonText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	healthHistory: PropTypes.object.isRequired,
	createSurgery: PropTypes.func.isRequired,
	removeSurgery: PropTypes.func.isRequired,
	updatePatientHealthHistory: PropTypes.func.isRequired,
	token: PropTypes.string.isRequired,
	history: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
	healthHistory: state.patient.health_history,
	token: state.telehealth.token,
});

const mapDispatchToProps = dispatch => ({
	createSurgery: surgery => dispatch(createSurgeryAction(surgery)),
	removeSurgery: surgeryId => dispatch(removeSurgeryAction(surgeryId)),
	updatePatientHealthHistory: healthHistory => dispatch(updatePatientHealthHistoryAction(healthHistory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Surgeries);
