import { fork, all } from 'redux-saga/effects';

/* ------------- Sagas ------------- */

import patientRootSaga from './patient';
import telehealthRootSaga from './telehealth';

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
	yield all([fork(telehealthRootSaga), fork(patientRootSaga)]);
}
