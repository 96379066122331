import React, { useEffect } from 'react';
import _ from 'lodash';
import { useAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLoader } from '../../services';
import {
	setSelectedProviderType,
	fetchAppointmentCost,
} from '../../redux/actions/telehealth';
import {
	setCurrentState,
	setSelectedState
} from '../../redux/actions/patient';
import { PageBackground } from '../../components';
import statesJSON from '../../services/states';
import api from '../../services/api';
import { IconSVG } from '../../components/icon';
import i18n from 'i18next';

const SelectProviderType = ({ history }) => {
	const alert = useAlert();
	const [Loader, setLoading] = useLoader(false);
	const providerTypes = useSelector(state => {
		const allowedProviderTypes = i18n.t('LABEL.allowed_provider_types', '3,5,6')
		const array = allowedProviderTypes.split(',').map(Number);
		return state.telehealth.provider_types.filter(t => array.includes(t.id));
	});
	const patient = useSelector(state => state.patient.currentPatientId);
	const patientProfile = useSelector(state => state.patient.patient_profile);
	const patientState = patientProfile.state.abbrev;
	const currentlyLocatedState = useSelector(state => state.patient.current_state);
	const currentlySelectedState = useSelector(state => state.patient.selected_state);
	const dispatch = useDispatch();
  
	const getLocation = async () => {
		if (navigator.geolocation) {
			return navigator.geolocation.getCurrentPosition(async position => {
				const response = await api.reverseGeoCode(position);
				if(response.ok){
					let currentStateAbbr;
					const currentState = _.get(response.data, 'principalSubdivision', null);
					if(currentState){
						currentStateAbbr = _.find(statesJSON, {name: currentState}).abbreviation;
					}
					// if(patientState !== currentStateAbbr){
					// 	alert.show(`It appears you are not in ${patientState}. Please make sure you select the state you are currently in.`)
					// }
					dispatch(setCurrentState(currentStateAbbr));
					dispatch(setSelectedState(currentStateAbbr));
				}
			});
		} 
		alert.show(i18n.t('LABEL.scenes.select-provider-type.location-not-supported', 'Geolocation is not supported by this browser.'));
	}

	const navigateToNextPage = providerType => {
		setLoading(true);
		dispatch(setSelectedProviderType(providerType));
		if (providerType.amount !== '$0') {
			// really don't like this
			history.push('/payment');
		} else {
			history.push('/application/reason-for-visiting');
		}
	};

	useEffect(() => {
		getLocation();
		providerTypes.map(provider => {
			dispatch(fetchAppointmentCost(patient, provider.id, patientState, (response) => {
				// provider.set('amount', `$${response.data.final_amount}`);
			}));
		})
	}, []);

	return (
		<div className="app">
			<Loader />
      
			<div className="app-header" style={{marginBottom: 0, borderBottomWidth: 0}}>
				<IconSVG
					name="mdLiveLogo"
					cssClasses="md-live-logo"
				/>
				<IconSVG
					name="logo"
					cssClasses="logo"
				/>
			</div>
			<div className="app-content-wrapper">
				<PageBackground
					name="select-provider-type"
					cssClass="app-content-wrapper-bg-right"
				/>
				<div className="app-sub-header" style={{ textAlign: 'center'}}>
					<p style={{ textAlign: 'center', marginBottom: 0 }}>
						{ i18n.t('LABEL.scenes.select-provider-type.select-state', 'Select the State and Type of Service') }
					</p>
					<p style={{textAlign: 'center', marginTop: 0, marginBottom: 25}}>
						{ i18n.t('LABEL.scenes.select-provider-type.state-currently-located', 'What state are you currently located in right now?') }
					</p>
					<select
						type="select"
						value={currentlySelectedState}
						onChange={(event) => {
							dispatch(setSelectedState(event.target.value !== 'XX'? event.target.value: null));
						}}
					>
						<option value="XX">
							{ i18n.t('LABEL.scenes.select-provider-type.select-state-placeholder', 'Select your state') }
						</option>
						{statesJSON.map(st => <option key={st.abbreviation} value={st.abbreviation}>{st.name}</option>)}
					</select>
					<p style={{marginTop: '5px', marginBottom: 25, fontSize: '14px', fontWeight: 400}}>
						{ i18n.t('LABEL.scenes.select-provider-type.different-state-msg', 'This can be different than the state you live in') }
					</p>
				</div>
				<div className="app-content-container">
					<div className="app-content list">
						{providerTypes.map((providerType, index) => (
							<div
								key={providerType.name}
								onClick={() => {
									if(!currentlySelectedState) {
										alert.show(i18n.t('LABEL.scenes.select-provider-type.please-select-state', 'Please select the state you are currently located in'));
									} else {
										navigateToNextPage(providerType)
									}
								}}
								className="app-content-block one-fourth"
								style={
									providerTypes.length < 4 && index === providerTypes.length - 1
										? { marginRight: 0, marginBottom: 0, padding: 0 }
										: {padding: 0}
								}
							>
								<div className={`list-item-header item-${index}`}>
									<h1 style={{color: '#3b3b3b'}}>{providerType.name}</h1>
								</div>
								<div className="app-content-block-body">
									<p style={{ height: 85 }}>{providerType.description}</p>
									<hr />
									<div style={{marginTop: 30, marginBottom: 30}}>
										<h1 style={{ fontSize: 28, margin: 0 }}>
											{ providerType.amount !== null ? providerType.amount : i18n.t('LABEL.scenes.select-provider-type.loaging-amount', 'Loading') }
										</h1>
										<p style={{ margin: 0 }}>{providerType.amountDescription}</p>
									</div>
									<hr />
									<p>
										<strong>
											{ i18n.t('LABEL.scenes.select-provider-type.use-it', 'Use it for:') }
										</strong>
									</p>
									{providerType.useCases.map(useCase => (
										<div key={useCase}>{useCase}</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="app-content-container">
					<div
						className="app-content col"
						style={{
							marginTop: 50,
							color: 'gray',
							fontSize: 12,
							textAlign: 'center',
							maxWidth: 800,
						}}
					>
						<p>
							{ i18n.t('LABEL.scenes.select-provider-type.tenant-pricing-msg', 'All services are provided through Telehealth partner, MDLIVE. Prices shown are only for first visit per patient. Pricing for future visits vary but are always less than the first visit.') }
						</p>
						<p dangerouslySetInnerHTML={{ __html: i18n.t('LABEL.scenes.select-provider-type.tenant-pricing-links', '')}}></p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(SelectProviderType);
