import {
	call,
	takeLatest,
	takeEvery,
	all,
	put,
	select,
} from 'redux-saga/effects';
import { getSelectedPatientId, getToken } from '../reducers/telehealth';
import api from '../../services/api';
import * as PATIENT from '../constants/patient';

import { getHealthHistory } from '../reducers/patient';

export function* fetchPatientProfilesForFamily(action) {
	yield put({ type: PATIENT.RESET_FAMILY_PROFILE });
	
	const { patients, cb } = action;

	for (let i = 0; i < patients.length; i += 1) {
		yield fetchPatientProfileForFamily(patients[i]);
	}

	cb();
}

export function* fetchPatientProfileForFamily(patient) {
	const response = yield call(
		api.fetchMDLivePatientProfile,
		patient.jwt,
		patient.user.id
	);

	if (response.ok) {
		yield put({
			type: PATIENT.ADD_FAMILY_PROFILE,
			profile: response.data,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* fetchPatientProfile(action) {
	const token = yield select(getToken);
	const response = yield call(
		api.fetchMDLivePatientProfile,
		token,
		action.userId
	);

	if (response.ok) {
		yield put({
			type: PATIENT.SET_PROFILE,
			profile: response.data,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* fetchPatientHealthHistory(action) {
	const token = yield select(getToken);
	const response = yield call(
		api.fetchMDLivePatientHealthHistory,
		token,
		action.userId
	);

	if (response.ok) {
		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: response.data,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* updatePatientPersonalInfo(action) {
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(api.updatePatient, token, patientId, {
		patient: action.data,
	});
	if (response.ok) {
		yield put({
			type: PATIENT.SET_PROFILE,
			profile: response.data,
		});
	} else {
		yield put({
			type: PATIENT.SET_PROFILE_ERROR,
			error: response.error,
		});
		yield call(api.postError, response.data.errors);
	}
  
	if(action.cb){
		action.cb(response.ok);
	}
}

export function* updatePatientHealthHistory(action) {
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	let healthHistory = yield select(getHealthHistory);
	const {
		medical_history: medicalHistory,
		...restOfHealthHistory
	} = action.healthHistory;
	healthHistory = {
		medical_history: {
			allergies: healthHistory.has_allergies === 'Yes',
			medications: healthHistory.is_taking_medication === 'Yes',
			surgeries: healthHistory.had_surgeries === 'Yes',
			medical_conditions: healthHistory.has_medical_conditions === 'Yes',
			...(medicalHistory || {}),
		},
		personal_information: {},
		allergy: [],
		medication: [],
		surgery: [],
		medical_condition: [],
		pediatric_profile: {},
		...(restOfHealthHistory || {}),
	};
	const response = yield call(api.updatePatientHealthHistory, token, patientId, {
		health_history: healthHistory,
	});

	if (response.ok) {
		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: response.data,
		});
	}else {
		yield call(api.postError, response.data.errors);
	}
}

export function* createHealthCondition(action) {
	const { condition } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);

	const response = yield call(
		api.createMedicalCondition,
		token,
		patientId,
		condition
	);

	if (response.ok) {
		let healthHistory = yield select(getHealthHistory);
		const { data } = response;

		const healthConditions = healthHistory.health_conditions.concat([data]);
		healthHistory = healthHistory.merge({
			health_conditions: healthConditions,
		});

		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: healthHistory,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* removeHealthCondition(action) {
	const { conditionId } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(
		api.removeMedicalCondition,
		token,
		patientId,
		conditionId
	);

	if (response.ok) {
		let healthHistory = yield select(getHealthHistory);

		const healthConditions = healthHistory.health_conditions.filter(
			({ medical_condition: condition }) => condition.id !== conditionId
		);
		healthHistory = healthHistory.merge({
			health_conditions: healthConditions,
		});

		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: healthHistory,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* createMedication(action) {
	const { medication } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);

	const response = yield call(
		api.createMedication,
		token,
		patientId,
		medication
	);

	if (response.ok) {
		let healthHistory = yield select(getHealthHistory);
		const {
			data: { medication: data },
		} = response;

		const medications = healthHistory.evr_medications.concat([data]);
		healthHistory = healthHistory.merge({ evr_medications: medications });

		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: healthHistory,
		});
	}else {
		yield call(api.postError, response.data.errors);
	}
}

export function* removeMedication(action) {
	const { medicationId } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(
		api.removeMedication,
		token,
		patientId,
		medicationId
	);

	if (response.ok) {
		let healthHistory = yield select(getHealthHistory);

		const medications = healthHistory.evr_medications.filter(
			medication => medication.id !== medicationId
		);
		healthHistory = healthHistory.merge({ evr_medications: medications });

		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: healthHistory,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* createAllergy(action) {
	const { allergy } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(api.createAllergy, token, patientId, allergy);

	if (response.ok) {
		let healthHistory = yield select(getHealthHistory);
		const {
			data: { allergy: data },
		} = response;

		const allergies = healthHistory.allergies.concat([data]);
		healthHistory = healthHistory.merge({ allergies });

		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: healthHistory,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* removeAllergy(action) {
	const { allergyId } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(api.removeAllergy, token, patientId, allergyId);

	if (response.ok) {
		let healthHistory = yield select(getHealthHistory);

		const allergies = healthHistory.allergies.filter(
			allergy => allergy.id !== allergyId
		);
		healthHistory = healthHistory.merge({ allergies });

		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: healthHistory,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* createSurgery(action) {
	const { surgery } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(api.createSurgery, token, patientId, surgery);

	if (response.ok) {
		let healthHistory = yield select(getHealthHistory);
		const {
			data: { surgery: data },
		} = response;

		const surgeries = healthHistory.surgeries_taken.concat([data]);
		healthHistory = healthHistory.merge({ surgeries_taken: surgeries });

		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: healthHistory,
		});
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* removeSurgery(action) {
	const { surgeryId } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(api.removeSurgery, token, patientId, surgeryId);

	if (response.ok) {
		let healthHistory = yield select(getHealthHistory);

		const surgeries = healthHistory.surgeries_taken.filter(
			surgery => surgery.id !== surgeryId
		);
		healthHistory = healthHistory.merge({ surgeries_taken: surgeries });

		yield put({
			type: PATIENT.SET_HEALTH_HISTORY,
			history: healthHistory,
		});
	}else {
		yield call(api.postError, response.data.errors);
	}
}

export function* getPatientBehavioralHistory(){
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(api.getBehavioralHistory, token, patientId);
  
	if (response.ok) {
		yield put({
			type: PATIENT.SET_BEHAVIORAL_HEALTH_HISTORY,
			behavioralHistory: response.data.behavioral_history,
		})
	} else {
		yield call(api.postError, response.data.errors);
	}
}

export function* updatePatientBehavioralHistory(action) {
	const { behavioralHistory, cb } = action;
	const patientId = yield select(getSelectedPatientId);
	const token = yield select(getToken);
	const response = yield call(api.updateBehavioralHistory, token, patientId, behavioralHistory);
  
	if (response.ok) {
		yield put({
			type: PATIENT.SET_BEHAVIORAL_HEALTH_HISTORY,
			behavioralHistory: response.data.behavioral_history
		})
	}

	cb(response);
}

export default function* root() {
	yield all([
		takeEvery(PATIENT.FETCH_PROFILES_FOR_FAMILY, fetchPatientProfilesForFamily),
		takeLatest(PATIENT.FETCH_PROFILE, fetchPatientProfile),
		takeLatest(PATIENT.UPDATE_PERSONAL_INFO, updatePatientPersonalInfo),
		takeLatest(PATIENT.FETCH_HEALTH_HISTORY, fetchPatientHealthHistory),
		takeLatest(PATIENT.UPDATE_HEALTH_HISTORY, updatePatientHealthHistory),
		takeLatest(PATIENT.CREATE_HEALTH_CONDITION, createHealthCondition),
		takeLatest(PATIENT.REMOVE_HEALTH_CONDITION, removeHealthCondition),
		takeLatest(PATIENT.CREATE_MEDICATION, createMedication),
		takeLatest(PATIENT.REMOVE_MEDICATION, removeMedication),
		takeLatest(PATIENT.CREATE_ALLERGY, createAllergy),
		takeLatest(PATIENT.REMOVE_ALLERGY, removeAllergy),
		takeLatest(PATIENT.CREATE_SURGERY, createSurgery),
		takeLatest(PATIENT.REMOVE_SURGERY, removeSurgery),
		takeLatest(PATIENT.UPDATE_BEHAVIORAL_HISTORY, updatePatientBehavioralHistory),
		takeLatest(PATIENT.GET_BEHAVIORAL_HISTORY, getPatientBehavioralHistory),
	]);
}
