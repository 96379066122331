import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import {
	createHealthCondition as createHealthConditionAction,
	removeHealthCondition as removeHealthConditionAction,
	updatePatientHealthHistory as updatePatientHealthHistoryAction,
} from '../../redux/actions/patient';
import api from '../../services/api';
import { IconSVG } from '../icon';
import i18n from 'i18next';

const useConditionSuggestions = () => {
	const [conditions, setConditions] = useState([]);

	const fetchConditionSuggestions = async (token, search) => {
		const response = await api.fetchConditionsSuggestions(token, search);
		if (response.ok) {
			setConditions(response.data.clinical_concepts);
		} else {
			setConditions([]);
		}
	}

	return [conditions, fetchConditionSuggestions];
}
 
const HealthConditions = props => {
	const [
		conditionSuggestions,
		fetchConditionSuggestions,
	] = useConditionSuggestions();

	const {
		healthHistory, 
		createHealthCondition, 
		removeHealthCondition, 
		updatePatientHealthHistory,
		submitButtonText,
		token,
		history,
	} = props;
	const {
		health_conditions: healthConditions,
		has_medical_conditions: hasMedicalConditions,
	} = healthHistory;

	return (
		<div>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.health-conditions.header', 'Do you have any health conditions?') }</h1>
			</div>
			<div className="app-content-block-body">
				{healthConditions.length > 0 && (
					<div className="col">
						<div className="row">
							<div className="col50 header">
								{ i18n.t('LABEL.components.forms.health-conditions.condition', 'Condition') }
							</div>
							<div className="col30 header">
								{ i18n.t('LABEL.components.forms.health-conditions.source', 'Source') }
							</div>
							<div className="col10" />
						</div>
						{healthConditions.map(({ medical_condition: condition }) => (
							<div key={condition.id} className="row tableBody">
								<div className="col50">{condition.condition}</div>
								<div className="col30">{condition.source}</div>
								<div className="col10">
									<button
										className="clean"
										type="button"
										onClick={() => removeHealthCondition(condition.id)}
									>
										<IconSVG
											name="delete"
											cssClasses="delete"
										/>
									</button>
								</div>
							</div>
						))}
					</div>
				)}
				<Formik
					initialValues={{
						condition: "",
						source: "",
					}}
					onSubmit={async (values, {resetForm}) => {
						createHealthCondition({ medical_condition: values });
						resetForm({})
					}}
				>
					{({ handleChange, setFieldValue }) => (
						<Form>
							<div className="input-container input-container-single" style={{marginTop: 20}}>
								<label htmlFor="condition">
									{ i18n.t('LABEL.components.forms.health-conditions.condition-label', 'Condition') }
								</label>
								<div className="row center">
									<Field
										name="condition"
										onChange={e => {
											fetchConditionSuggestions(token, e.nativeEvent.target.value);
											handleChange(e);
										}}
										autoComplete="off"
									/>
									<button type="submit" style={{marginTop: 0}}>
										{ i18n.t('LABEL.components.forms.health-conditions.add-btn', 'Add') }
									</button>
									{conditionSuggestions.length > 0 && (
										<div className="suggestions-container">
											{conditionSuggestions.map(condition => (
												<div
													className="suggestion-item"
													key={condition.concept_id}
													onMouseDown={() => {
														setFieldValue("condition", condition.title);
													}}
												>
													<div><strong>{condition.title}</strong></div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			<div className="app-content-block-footer">
				<div className="button-container" style={{justifyContent: 'space-between'}}>
					<button
						onClick={() => {
							history.go('application/reason-for-visiting');
						}}
						type="button"
						className="default"
						style={{marginTop: 15}}
					>
						{ i18n.t('LABEL.components.forms.health-conditions.back-btn', 'Back') }
					</button>
					<button
						onClick={() => {
							updatePatientHealthHistory({
								medical_history: {
									medical_conditions: healthConditions.length > 0,
								},
							});
							props.onSubmit();
						}}
						type="submit"
					>
						{ healthConditions.length > 0 ? submitButtonText : i18n.t('LABEL.components.forms.health-conditions.not-have-condition-btn', 'No, I do not have any health conditions.') }
					</button>
				</div>
			</div>
		</div>
	);
};

HealthConditions.propTypes = {
	submitButtonText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	healthHistory: PropTypes.object.isRequired,
	createHealthCondition: PropTypes.func.isRequired,
	removeHealthCondition: PropTypes.func.isRequired,
	updatePatientHealthHistory: PropTypes.func.isRequired,
	token: PropTypes.string.isRequired,
	history: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
	healthHistory: state.patient.health_history,
	token: state.telehealth.token,
});

const mapDispatchToProps = dispatch => ({
	createHealthCondition: healthCondition => dispatch(createHealthConditionAction(healthCondition)),
	removeHealthCondition: conditionId => dispatch(removeHealthConditionAction(conditionId)),
	updatePatientHealthHistory: healthHistory => dispatch(updatePatientHealthHistoryAction(healthHistory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthConditions);
