import './config/ReactotronConfig';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { transitions, positions, types, Provider as AlertProvider } from 'react-alert';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { isMobile } from 'react-device-detect';
import { IconSVG } from './components/icon';

const rootElement = document.getElementById('root');

const options = {
	position: positions.BOTTOM_LEFT,
	timeout: 4000,
	offset: '30px',
	containerStyle: {
		transform: 'translate(15px, -15px)',
		width: isMobile ? '65%' : 'auto'
	},
	// you can also just use 'scale'
	transition: transitions.SCALE
};

const AlertTemplate = ({ style, options, message, close }) => {
	const [isModalOpened, setIsModalOpened] = useState(true);
	let bgColor = '#429fac';

	if (options.type === types.SUCCESS) {
		bgColor = '#608624';
	}

	if (options.type === types.ERROR) {
		bgColor = '#d04747';
	}

	if (options && options.timeout && options.timeout > 0) {
		setTimeout(() => {
			setIsModalOpened(false);
		}, options.timeout);
	}
	
	useEffect(() => {
		const pointerEventsStyleValue = isModalOpened ? 'none' : 'auto';
		rootElement.style.pointerEvents = pointerEventsStyleValue;
	}, [isModalOpened]);
	
	const closePopup = function() {
		setIsModalOpened(false);
		close();
	}

	return (
		<div
			className="custom-alert"
			style={
				{
					backgroundColor: bgColor,
				}
			}
		>
			{
				options.type === types.SUCCESS && (
					<IconSVG
						name="icon-success"
						cssClasses="custom-alert__icon"
					/>
				)
			}
			<span className="custom-alert__text">{message}</span>
			<IconSVG
				name="icon-close-white"
				cssClasses="custom-alert__button"
				onClickFn={closePopup}
			/>
		</div>
	);
}

const Root = () => (
	<AlertProvider template={AlertTemplate} {...options}>
		<App />
	</AlertProvider>
)

ReactDOM.render(<Root />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
