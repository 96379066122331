import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Application from '../scenes/Application';
import Appointments from '../scenes/Appointments';
import SelectPatient from '../scenes/SelectPatient';
import SelectProviderType from '../scenes/SelectProviderType';
import Payment from '../scenes/Payment';
import LegalText from '../scenes/LegalText';

const AppRoutes = () => (
	<Router>
		<Switch>
			<Route name="application" path="/application" component={Application} />
			<Route name="payment" path="/payment" component={Payment} />
			<Route
				name="selectProviderType"
				path="/provider-type"
				component={SelectProviderType}
			/>
			<Route name="appointments" path="/appointments" component={Appointments} />
			<Route name="read" path="/read" component={LegalText} />
			<Route name="selectPatient" component={SelectPatient} />
		</Switch>
	</Router>
);

export default AppRoutes;
