import React from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ApplicationRoutes from '../../navigation/ApplicationRoutes';
import { IconSVG } from '../../components/icon';
import i18n from 'i18next';

const Application = (props) => {
	const providerType = useSelector(
		(state) => state.telehealth.selected_provider_type
	);
	const patient = useSelector((state) => state.patient.patient_profile);
	const {
		history,
		location: { pathname: currentPath },
	} = props;

	if (!patient) {
		history.push('/select_patient');
	} else if (!providerType) {
		history.push('/select_patient');
	}

	const pages = [{
			name: i18n.t('LABEL.scenes.application.step-visit-reason', 'Reason for Visiting'),
			path: '/application/reason-for-visiting'
		}, {
			name: i18n.t('LABEL.scenes.application.step-health-profile', 'Health Profile'),
			path: '/application/health-profile',
			pages: [{
					name: i18n.t('LABEL.scenes.application.step-personal-info', 'Personal Info'),
					path: '/application/health-profile/personal-info',
				}, {
					name: i18n.t('LABEL.scenes.application.step-health-conditions', 'Health Conditions'),
					path: '/application/health-profile/health-conditions',
				}, {
					name: i18n.t('LABEL.scenes.application.step-medications', 'Medications'),
					path: '/application/health-profile/medications'
				}, {
					name: i18n.t('LABEL.scenes.application.step-allergies', 'Allergies'),
					path: '/application/health-profile/allergies'
				}, {
					name: i18n.t('LABEL.scenes.application.step-surgeries', 'Surgeries'),
					path: '/application/health-profile/surgeries'
				},
			],
		}, {
			name: i18n.t('LABEL.scenes.application.step-pharmacy-search', 'Pharmacy'),
			path: '/application/pharmacy-search'
		}, {
			name: i18n.t('LABEL.scenes.application.step-choose-provider', 'Choose Provider'),
			path: '/application/choose-provider'
		}, {
			name: i18n.t('LABEL.scenes.application.step-schedule-call', 'Schedule Appointment'),
			path: '/application/schedule-call'
		},
		// { name: 'Video App', path: '/application/video-app' },
		// { name: 'Payment', path: '/application/payment' },
	];
	const isMedicalVisit = providerType && providerType.name === 'Medical Visit';
	if (!isMedicalVisit) {
		pages[1].pages.push({
			name: i18n.t('LABEL.scenes.application.step-behavioral', 'Behavioral'),
			path: '/application/health-profile/behavioral',
		});
	}

	const getProgress = (path) => {
		const currentPageIndex = pages.findIndex((page) =>
			matchPath(currentPath, { path: page.path, exact: false, strict: false })
		);
		// const pageIndex = pages.findIndex((page) => page.path.indexOf(path) === 0);

		return {
			percentage: (currentPageIndex / pages.length) * 100,
			currentPageIndex,
			total: pages.length,
		};
	};

	const getProgressItemClassName = (path) => {
		const currentPageIndex = pages.findIndex((page) =>
			matchPath(currentPath, { path: page.path, exact: false, strict: false })
		);
		const pageIndex = pages.findIndex((page) => page.path.indexOf(path) === 0);
		if (currentPageIndex === pageIndex) {
			return 'current';
		}
		if (pageIndex < currentPageIndex) {
			return 'complete';
		}
		return '';
	};

	const getPageName = (page) => {
		if (page.pages) {
			const currentPageIndex = page.pages.findIndex((p) =>
				matchPath(currentPath, { path: p.path, exact: false, strict: false })
			);
			return `${page.name} ${currentPageIndex === -1 ? 1 : currentPageIndex + 1}/${
				page.pages.length
			}`;
		}
		return page.name;
	};

	const renderHeader = () => {
		return (
			<>
				<IconSVG
					name="mdLiveLogo"
					cssClasses="md-live-logo"
				/>
				<IconSVG
					name="logo"
					cssClasses="logo"
				/>
				<ul className="progress">
					<li className="complete">
						<div className="line-container">
							<div className="line" />
							<div className="circle">
								<div className="check" />
							</div>
							<div className="line" />
						</div>
						<Link to="/">{patient.first_name}</Link>
					</li>
					{pages.map((page) => (
						<li key={page.name} className={getProgressItemClassName(page.path)}>
							<div className="line-container">
								<div className="line" />
								<div className="circle">
									<div className="check" />
								</div>
								<div className="line" />
							</div>
							<Link to={page.path}>{getPageName(page)}</Link>
						</li>
					))}
				</ul>
			</>
		);
	};

	const renderMobileHeader = () => {
		const { percentage, currentPageIndex, total } = getProgress(currentPath);
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div style={{ width: 50, height: 50 }}>
					<CircularProgressbar
						value={percentage}
						text={`${currentPageIndex}/${total}`}
						styles={buildStyles(
							{
								textColor: '#ff755a',
								pathColor: '#ff755a',
							}
						)}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end'
					}}
				>
					<span className="mobileHeaderTitle">{pages[currentPageIndex].name}</span>
					{currentPageIndex < pages.length - 1 && (
						<span className="mobileHeaderSubTitle">
							{ i18n.t('LABEL.scenes.application.next-step', 'Next: {{step}}',
								{ step: pages[currentPageIndex + 1].name }) }
						</span>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className="app">
			<BrowserView>
				<div className="app-header">{renderHeader()}</div>
			</BrowserView>
			<MobileView>
				<div className="app-header">{renderMobileHeader()}</div>
			</MobileView>
			<div className="app-content-container">
				<ApplicationRoutes />
			</div>
		</div>
	);
};

export default withRouter(Application);
