import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useAlert, types } from 'react-alert';
import { useLoader } from '../../services'
import {
	fetchChiefComplaintSearchResults,
	fetchProviders,
	setChiefComplaint,
	setChiefComplaintComment,
	uploadImage,
} from '../../redux/actions/telehealth';
import { IconSVG } from '../icon';
import i18n from 'i18next';

const VisitReason = props => {
	const [Loader, setLoading] = useLoader(false);

	const alert = useAlert();
	const [complaint, setComplaint] = useState('');
	const [complaintComment, setComplaintComment] = useState('');
	const [uploadedImage, setUploadedImage] = useState(null);
	const [nextButtonPressed, setNextButtonPressed] = useState(false);

	let moreRef = useRef(null);

	const providerType = useSelector(
		state => state.telehealth.selected_provider_type
	);
	const chiefComplaintSearchResults = useSelector(
		state => state.telehealth.chief_complaint_results
	);
	const chiefComplaint = useSelector(state => state.telehealth.chief_complaint);
	const chiefComplaintComment = useSelector(
		state => state.telehealth.chief_complaint_comment
	);
	const patient = useSelector(state => state.patient.patient_profile);
	const patientAddressState = useSelector(
		state => state.patient.patient_profile.state.abbrev
	);
	const patientSelectedState = useSelector(
		state => state.patient.selected_state
	);

	const dispatch = useDispatch();

	let providerTypeId = _.get(providerType, 'id', 3);
	if (providerTypeId === 3 && patient.is_minor) providerTypeId = 2;

	useEffect(() => {
		dispatch(fetchProviders(patientSelectedState || patientAddressState, providerTypeId));
		if (chiefComplaint) setComplaint(chiefComplaint.title);
		if (chiefComplaintComment) setComplaintComment(chiefComplaintComment);
	}, [chiefComplaint, chiefComplaintComment, patientAddressState]);

	const focusOnNextElement = () => {
		moreRef.focus();
	};

	const readImageData = image => {
		setLoading(true);
		const imageName = image.name;
		const imageType = image.type;
		const reader = new FileReader();
		reader.onload = img => {
			const source = img.target.result.split(';');
			const base64Data = source[1].split(',')[1];
			setUploadedImage(base64Data);
			dispatch(uploadImage({
				base64Data,
				imageName,
				imageType,
			}, (success) => {
				setLoading(false);
				if (success){
					alert.show(i18n.t('LABEL.components.forms.visit-reason.img-uploaded', 'Image was successfully uploaded'), { type: types.SUCCESS });
				} else {
					alert.show(i18n.t('LABEL.components.forms.visit-reason.img-not-uploaded', 'Sorry, we were unable to upload the image at this time.  Please try again'), { type: types.ERROR });
				}
			}));
		};
		reader.readAsDataURL(image);
	};

	return (
		<div>
			<Loader />
			<div className="app-content-block-header">
				<h1>
					{ i18n.t('LABEL.components.forms.visit-reason.header',
						'What\'s your reason for your {{providerType}} visit today, {{name}} ?',
						{ providerType: _.get(providerType, 'name', 'Unknown'), name: _.get(patient, 'first_name', 'Unknown') }) }
				</h1>
			</div>
      
			<div className="app-content-block-body">
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={{
						visit_reason_query: '',
						visit_reason_responses: chiefComplaintSearchResults,
						visit_reason_comment: '',
					}}
					validate={values => {
						const errors = {};
						if (!values.visit_reason_query || !chiefComplaint) {
							errors.visit_reason_query = i18n.t('LABEL.components.forms.visit-reason.reason-query-error-msg', 'You must select a reason from the list');
						}
						if (!values.visit_reason_comment) {
							errors.visit_reason_comment = i18n.t('LABEL.components.forms.visit-reason.reason-comment-error-msg', 'Please tell us more about what is wrong');
						}
						return errors;
					}}
					onSubmit={values => {
						dispatch(setChiefComplaintComment(values.visit_reason_comment));
						if (props.onSubmit) {
							props.onSubmit(values);
						}
					}}
				>
					{({ errors, handleBlur, handleChange, touched }) => {
						return (
							<Form>
								<div className="input-container">
									{touched.visit_reason_query && errors.visit_reason_query && nextButtonPressed ? (
										<label htmlFor="visit_reason_query" className="error">
											{errors.visit_reason_query}
										</label>
									) : (
										<label htmlFor="visit_reason_query">
											{ i18n.t('LABEL.components.forms.visit-reason.reason-query-label', 'Please tell us what is wrong, then select the closest match from the list.') }
										</label>
									)}
									<Field
										name="visit_reason_query"
										onChange={e => {
											setNextButtonPressed(false);
											setComplaint(e.target.value);
											dispatch(
												fetchChiefComplaintSearchResults(e.nativeEvent.target.value)
											);
											dispatch(
												setChiefComplaint(null)
											);
											handleChange(e);
										}}
										onBlur={handleBlur}
										value={complaint}
										autoComplete="off"
									/>
									{chiefComplaintSearchResults.length > 0 && (
										<div className="suggestions-container">
											{chiefComplaintSearchResults.map(complaint => (
												<div
													className="suggestion-item"
													key={`visit_reason_response_${complaint.id}`}
													onMouseDown={event => {
														event.preventDefault();
														setNextButtonPressed(false);
														dispatch(setChiefComplaint(complaint));
														focusOnNextElement();
													}}
												>
													<div>
														<strong>{complaint.title}</strong>
													</div>
													{complaint.title.toLowerCase() !==
														complaint.alternate_term.toLowerCase() && (
														<div>{complaint.alternate_term}</div>
													)}
												</div>
											))}
										</div>
									)}
								</div>
								<div className="input-container">
									{touched.visit_reason_comment && errors.visit_reason_comment && nextButtonPressed ? (
										<label htmlFor="visit_reason_comment" className="error">
											{errors.visit_reason_comment}
										</label>
									) : (
										<label htmlFor="visit_reason_comment">
											{ i18n.t('LABEL.components.forms.visit-reason.reason-comment-label', 'Please describe your symptoms in more detail') }
										</label>
									)}
									<Field
										name="visit_reason_comment"
										innerRef={el => (moreRef = el)}
										value={complaintComment}
										onChange={e => {
											setNextButtonPressed(false);
											setComplaintComment(e.target.value);
											handleChange(e);
										}}
										onBlur={handleBlur}
									/>
								</div>
								<div style={{ textAlign: "center" }}>
									<p style={{width: '100%', textAlign: 'center'}}>
										{ i18n.t('LABEL.components.forms.visit-reason.img-uploading-msg', 'Would you like to upload an image?') }
									</p>
									{uploadedImage && (
										<img src={`data:image/png;base64,${uploadedImage}`} style={{ height: 100, marginBottom: 10 }} />
									)}
									<Dropzone onDrop={acceptedFiles => readImageData(acceptedFiles[0])}>
										{({getRootProps, getInputProps}) => (
											<section>
												<div id="file-upload-container" {...getRootProps()}>
													<input {...getInputProps()} />

													<IconSVG
														name="file"
														cssClasses="uploader-icon"
													/>

													<p className="uploader-description">
														{ i18n.t('LABEL.components.forms.visit-reason.drag-and-drop-msg', 'Drag and drop an image here, or click to select one from your file system') }
													</p>
													{uploadedImage && (
														<p className="uploader-description" style={{width: '100%', textAlign: 'center'}}>
															{ i18n.t('LABEL.components.forms.visit-reason.second-img-uploading-msg', 'Uploading a second image will replace the first') }
														</p>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								
								</div>
								<div className="row" style={{justifyContent: 'space-between', alignItems: 'center'}}>

									<div className="button-container-left">
										<button
											type="button" 
											onClick={() => props.onBack()}
											className="default"
											style={{marginTop: 15}}
										>
									  		{ i18n.t('LABEL.components.forms.visit-reason.back-btn', 'Back') }
										</button>
									</div>            
									<div className="button-container-right">
										<button type="submit" onClick={() => setNextButtonPressed(true)}>
											{ i18n.t('LABEL.components.forms.visit-reason.next-btn', 'Next') }
										</button>
									</div>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</div>
	);
};

VisitReason.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default VisitReason;
