import React from 'react';
import PropTypes from 'prop-types';

const IconSVG = ({ name, cssClasses, altText, onClickFn, cssStyles = {} }) => {
	const srcUrl = `/resources/mdlive/images/svg/${name}.svg`;
	const defaultSRC = process.env.PUBLIC_URL + `/assets/img/${name}.svg`;
	const clickHandler = onClickFn || IconSVG.defaultProps.onClickFn;

	return(
		<img
			src={srcUrl}
			className={cssClasses}
			alt={altText}
			style={cssStyles}
			onClick={() => {
				clickHandler();
			}}
			onError={ ({ currentTarget }) => {
				currentTarget.onError = null;
				currentTarget.src = defaultSRC;
			}}
		/>
	);
}

IconSVG.defaultProps = {
	cssClasses: '',
	altText: '',
	onClickFn: () => {}
};

IconSVG.propTypes = {
	name: PropTypes.string.isRequired,
	cssClasses: PropTypes.string,
	altText: PropTypes.string,
	onClickFn: PropTypes.func
};

export default IconSVG;
