import React from 'react';
import { withRouter } from 'react-router-dom';
import VisitReasonForm from '../../components/forms/VisitReason';
import { PageBackground } from '../../components';

const HealthHistory = props => {
	const { history } = props;

	const navigateToNextPage = () => {
		history.push('/application/health-profile');
	};
	const navigateBack = () => {
		history.goBack();
	}
	return (
		<div className="app-content-wrapper">
			<PageBackground
				name="wizard-reason-for-visiting"
				cssClass="app-content-wrapper-bg-right"
			/>
			<div className="app-content" style={{ maxWidth: 1000 }}>
				<div className="app-content-block">
					<VisitReasonForm submitButtonText="Next" onSubmit={navigateToNextPage} onBack={navigateBack} />
				</div>
			</div>
		</div>
	);
};

export default withRouter(HealthHistory);
