import Immutable from "seamless-immutable";
import { createSelector } from "reselect";

import {
	SET_USER,
} from "../constants/user";

const initialState = Immutable({
	firstName: "",
	lastName: "",
});

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_USER:
			return state.merge(action.user);
		case 'RESET':
			return initialState;
		default:
			return state;
	}
};

const userState = state => state.user;

export const getFirstName = createSelector(
	[userState],
	state => state.firstName,
);

export const getLastName = createSelector(
	[userState],
	state => state.firstName,
);
