let _store;

function setTopLevelStore(store) {
	_store = store;
}

function getStore() {
	return _store;
}

export default {
	getStore,
	setTopLevelStore,
};
